import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';
import { toast } from 'react-toastify';

export const submitLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(loginIsLoading());
    return toast
      .promise(jwtService.signInWithEmailAndPassword(email, password), {
        pending: 'Accesso',
        success: 'Benvenuto 👌',
        error: {
          render({ data }) {
            const {
              response: {
                data: { error },
              },
            } = data;
            switch (error.message) {
              case 'Invalid identifier or password':
                return 'Utente o password non validi';
              case 'Your account email is not confirmed':
                return 'L\'email del tuo account non è confermata';
              default:
                return 'Error general';
            }
          },
        },
      })
      .then((_) => {
        jwtService
          .signInWithToken()
          .then((_user) => {
            dispatch(setUserData(_user));
            return dispatch(loginSuccess());
          })
          .catch((error) => {
            toast.error(error.message);
            return dispatch(loginError(error.message));
          });
      })
      .catch((errors) => {
        if (errors && errors.isAxiosError) {
          const {
            response: {
              data: { error },
            },
          } = errors;
          return dispatch(loginError(error.message));
        } else {
          return dispatch(loginError(errors));
        }
      });
  };

const initialState = {
  success: false,
  errors: [],
  isLoading: false,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginReset: () => ({
      ...initialState,
    }),
    loginSuccess: (state, action) => {
      state.success = true;
      state.isLoading = false;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.isLoading = false;
      state.errors = action.payload;
    },
    loginIsLoading: (state, action) => {
      state.isLoading = true;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError, loginIsLoading, loginReset } =
  loginSlice.actions;

export default loginSlice.reducer;
