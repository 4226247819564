import React from 'react';
import { OrdersStatus } from '../../Personal/OrdersHistory/util';
import SectionOrderPendingValidation from '../../../components/SectionOrderPendingValidation';
import SectionOrderPayment from '../../../components/SectionOrderPayment';
import SectionOrderSamplesTable from '../../../components/SectionOrderSamplesTable';
import SectionOrderCommunications from '../../../components/SectionOrderCommunications';

function OrdersBody({ orderId, samples, order }) {
  if (order?.status === OrdersStatus.RNS_FEM) {
    return <SectionOrderPendingValidation />;
  } else if (order?.status) {
    return (
      <>
        {order?.status === OrdersStatus.PAGAMENTO && (
          <SectionOrderPayment
            amount={order.amount}
            paymentMethod={order.payment_method}
          />
        )}
        {samples?.length > 0 && (
          <SectionOrderSamplesTable samples={samples} order={order} />
        )}
        <SectionOrderCommunications orderId={orderId} />
      </>
    );
  } else {
    return null;
  }
}

export default OrdersBody;
