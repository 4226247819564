import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import orderStyles from '../Order.module.scss';
import SectionSubTitle from 'app/main/components/SectionSubtitle';
import React, { useEffect, useState } from 'react';
import { default as CS } from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import styles from '../Summary/Summary.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  addCommissionToCart,
  addShippingToCart,
  createNewOrderInLoop,
} from '../store/orderSlice';

const classNames = CS.bind(orderStyles);
const SHIPPING_COMMISSION = 3.0;
const paymentMethods = [
  {
    type: 'transfer',
    id: 'BONB',
    label: 'Bonifico Bancario',
    commission: 0,
    description: (
      <p>
        Bonifico Bancario Per effettuare il pagamento utilizza le seguenti
        coordinate,
        <br /> indicando come causale il numero dell’ordine o il tuo nome
        <br />{' '}
        <span className={'fw-bold'}>
          Beneficiario: FEM2 – Ambiente Srl
        </span>{' '}
        <br />
        Banca Popolare di Sondrio <br />
        <span className={'fw-bold'}>
          IBAN IT27 O056 9601 6280 0001 0296 X79
        </span>
        <br />
        (attenzione il primo simbolo dopo IT27 è una lettera “o” <br />
        seguito dal numero zero)
      </p>
    ),
  },
  {
    type: 'postal',
    label: 'Bollettino Postale',
    id: 'BOLP',
    commission: 0.5,
    description: (
      <p>
        Bollettino Postale Per effettuare il pagamento utilizza le seguenti
        coordinate,
        <br />
        indicando nella causale il numero dell’ordine o il tuo nome.
        <br />
        Beneficiario: <span className={'fw-bold'}>Fem2 – Ambiente Srl</span>
        <br />
        <span className={'fw-bold'}>C/C n. 07567082</span>
      </p>
    ),
  },
  {
    type: 'paypal',
    label: 'Paypal',
    id: 'PPAL',
    commission: (total) => (total * 0.04 + 0.35).toFixed(2),
    description: (
      <p>
        PayPal Per effettuare il pagamento tramite PayPal, accedi alla
        <br />
        pagina seguente cliccando{' '}
        <span className={'fw-bold'}>"INVIA ORDINE".</span>
        <br />
        In caso di problemi, puoi effettuare direttamente <br />
        il pagamento a{' '}
        <span className={'fw-bold'}>ordini@fem2ambiente.com</span> connettendoti
        al <br />
        sito www.paypal.com <br />
        Per maggiori dettagli sulla procedura, clicca qui
      </p>
    ),
  },
];

const Payment = ({ handlePrevStep }) => {
  const [selectedPayment, setSelectedPayment] = useState(paymentMethods[0]);
  const {
    cart: { total, commission, items, shipping },
  } = useSelector(({ order }) => order.order);
  const handleChangePaymentMethod = (index) => {
    const paymentMethod = paymentMethods[index];
    let newCommission;
    setSelectedPayment(paymentMethod);
    if (typeof paymentMethod.commission === 'function') {
      newCommission = paymentMethod.commission(total);
    } else {
      newCommission = paymentMethod.commission;
    }
    dispatch(addCommissionToCart(newCommission));
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    client: { fem_credit },
  } = useSelector(({ auth }) => auth.user.data);

  const { creditsConsumed, serviceToPay } = (function (femCredit, total) {
    const totalAndShipping = Number(total) + shipping;

    if (femCredit && femCredit > 0) {
      const creditsRest = (
        Number(femCredit) - Number(totalAndShipping)
      ).toFixed(2);
      let creditsConsumed = Number(totalAndShipping).toFixed(2);
      let serviceToPay = 0;
      if (creditsRest < 0) {
        creditsConsumed = Number(femCredit);
        serviceToPay = Math.abs(Number(creditsRest));
      }
      return { creditsConsumed: Number(creditsConsumed), serviceToPay };
    }

    return {
      creditsConsumed: 0,
      serviceToPay: Number(totalAndShipping).toFixed(2),
    };
  })(fem_credit, total);

  const handleNavigateToOrders = async () => {
    dispatch(
      createNewOrderInLoop(
        'PAYMENT',
        history,
        selectedPayment.id,
        serviceToPay,
        creditsConsumed,
      ),
    );
  };

  useEffect(() => {
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (
          item?.analyses?.length > 0 &&
          item.analyses.some((analyse) => analyse.print.value)
        ) {
          dispatch(addShippingToCart(SHIPPING_COMMISSION));
        } else {
          dispatch(addShippingToCart(0));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <>
      <Row className={classNames('p-2 p-md-3 p-lg-4', orderStyles.wrapper)}>
        <SectionSubTitle>PAGAMENTO</SectionSubTitle>
        {serviceToPay > 0 && (
          <Row className={'d-flex justify-content-between mb-5'}>
            <Col sm={12} md={5}>
              <p>Seleziona una modalità di pagamento</p>
              <Form noValidate>
                {paymentMethods.map(({ type, label }, index) => {
                  return (
                    <Row className={'my-4 ml-4'} key={index}>
                      <Col>
                        <Form.Check
                          type="radio"
                          name="paymentMethod"
                          checked={selectedPayment.type === type}
                          label={label}
                          onChange={() => handleChangePaymentMethod(index)}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Form>
            </Col>
            <Col
              sm={12}
              md={6}
              className={classNames('px-4', orderStyles.greenBackground)}
            >
              <div className={'my-3'}>
                <span className={'fw-bold'}>{selectedPayment.label}</span>
              </div>
              {selectedPayment.description}
            </Col>
          </Row>
        )}
        <div className={'px-5'}>
          <Row className={'mt-5'}>
            <div className={'dropdown-divider'} />
          </Row>
          <Row>
            <Col sm={6} />
            <Col sm={6}>
              <Row>
                <Col sm={8}>
                  <p>Importo TOTALE come da listino</p>
                </Col>
                <Col sm={4}>
                  <p className={'text-end'}>€ {Number(total).toFixed(2)}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <p>COMMISSIONI</p>
                </Col>
                <Col sm={4}>
                  <p className={'text-end'}>€ {commission}</p>
                </Col>
              </Row>
              {shipping > 0 && (
                <Row>
                  <Col sm={8}>
                    <p>SPEDIZIONI</p>
                  </Col>
                  <Col sm={4}>
                    <p className={'text-end'}>€ {shipping}</p>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {fem_credit > 0 && (
            <Row>
              <Col sm={6} />
              <Col sm={6}>
                <Row>
                  <Col sm={8}>
                    <p>Crediti disponibili</p>
                  </Col>
                  <Col sm={4}>
                    <p className={'text-end'}>€ {fem_credit}</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <p>Crediti da sottrarre</p>
                  </Col>
                  <Col sm={4}>
                    <p className={'text-end'}>- € {creditsConsumed}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <div className={'dropdown-divider'} />
          </Row>
          <Row>
            <Col sm={6} />
            <Col sm={6}>
              <Row>
                <Col sm={8}>
                  <p>
                    <span className={'fw-bold'}>TOTALE SERVIZI</span> (iva
                    inclusa)
                  </p>
                </Col>
                <Col sm={4}>
                  <p className={'text-end fw-bold'}>
                    € {(Number(serviceToPay) + Number(commission)).toFixed(2)}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className={'d-flex justify-content-between mt-5 mb-5'}>
          <Col sm={6}>
            <p>
              Termini e Condizioni di vendita e Cookies{' '}
              <NavLink to="/terms-and-conditions">qui</NavLink> <br />
              Condizioni Privacy{' '}
              <NavLink to="/terms-and-conditions">qui</NavLink>
            </p>
          </Col>
          <Col sm={6} />
        </Row>
        <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
          <Col sm={6}>
            <Button type="button" onClick={handlePrevStep}>
              <Image
                width="10px"
                height="10px"
                className="img-fluid me-2"
                src={'/assets/icons/prev_arrow.svg'}
              />
              RITORNA AL RIEPILOGO
            </Button>
          </Col>
          <Col
            sm={6}
            className={
              'd-flex d-sm-flex justify-content-md-end justify-content-sm-center'
            }
          >
            <Button type="button" onClick={handleNavigateToOrders}>
              INVIA ORDINE
              <Image
                width="10px"
                height="10px"
                className="img-fluid ms-2"
                src={'/assets/icons/next_arrow.svg'}
              />
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Payment;
