import React from 'react';
import { Badge, Button, Col, Image, Row, Table } from 'react-bootstrap';
import { default as CS } from 'classnames/bind';
import SectionSubTitle from 'app/main/components/SectionSubtitle';
import styles from './Summary.module.scss';
import Moment from 'react-moment';

const classNames = CS.bind(styles);

const Summary = ({ samples, handleNextStep, totalOrder }) => {
  console.log(samples);
  return (
    <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
      <SectionSubTitle>RIEPILOGO</SectionSubTitle>
      <Row className={classNames(styles.greenBackground, styles.topShadow)}>
        <Col
          sm={12}
          md={10}
          className={'px-2 px-md-3 px-lg-4 pt-2 pt-md-3 pt-lg-4'}
        >
          <p>
            Clicca <a
              href="https://avifauna.fem2ambiente.com/cosa-spedire-2/"
              target="_blank"
              rel='noreferrer'
            >qui</a> per visualizzare le istruzioni
            per il prelievo dei campioni.
          </p>
          <p>
            ATTENZIONE! Per snellire le operazioni di valutazione e
            registrazione dei campioni da parte dei tecnici di laboratorio, e
            ridurre i tempi di attesa dei risultati, E’ NECESSARIO riportare
            sulla bustina contente il singolo campione oltre al relativo
            identificativo ANCHE il numero sequenziale di inserimento come
            riportato nella lista di riepilogo sottostante. Tale numero è
            riportato nella prima colonna della tabella.
          </p>
        </Col>
        <Col sm={12} md={2}>
          <Image src={'/assets/analysis.png'} />
        </Col>
      </Row>
      <Row className={'custom-background-pricing-table pl-4'}>
        <Table hover responsive="lg" className={styles.orderTable}>
          <thead>
            <tr>
              <th>Identificativo campione</th>
              <th>Specie</th>
              <th>Analisi/Stato/Attestai</th>
            </tr>
          </thead>
          <tbody>
            {samples.length > 0 &&
              samples.map(
                (
                  {
                    createdAt,
                    id,
                    status,
                    identifier,
                    other_species,
                    analyses,
                  },
                  index,
                ) => (
                  <tr key={id}>
                    <td>
                      <b className="me-2">{index + 1}</b>
                      <div className="d-flex flex-column">
                        <i>{identifier}</i>
                        <Moment format="LLLL">{createdAt}</Moment>
                      </div>
                    </td>
                    <td>{other_species}</td>
                    <td className="d-flex flex-column">
                      {analyses.map(
                        ({ analysis_type, price, id, certificate_paper }) => {
                          const _price = Number(
                            price + (certificate_paper ? +2 : 0),
                          ).toFixed(2);
                          return (
                            <div key={id}>
                              <span className={styles.textForAnalyse}>
                                {analysis_type} { }
                              </span>{' '}
                              <Badge bg="dark">€ {_price}</Badge>
                            </div>
                          );
                        },
                      )}
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </Table>
        <p className="d-flex justify-content-end">
          <span className={'fw-bold'}>TOTALE SERVIZO</span> (iva inclusa): €
          {totalOrder.toFixed(2)}
        </p>
      </Row>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <Col
          sm={6}
          className={
            'd-flex d-sm-flex justify-content-md-end justify-content-sm-center'
          }
        >
          <Button type="button" onClick={handleNextStep}>
            INVIA ORDINE
            <Image
              width="10px"
              height="10px"
              className="img-fluid ms-2"
              src={'/assets/icons/next_arrow.svg'}
            />
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default Summary;
