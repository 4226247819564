import Register from './Register';
import authRoles from 'app/auth/authRoles';

const RegisterConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: true,
          personalMenu: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/register',
      component: Register,
    },
  ],
};

export default RegisterConfig;
