import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import Logo from 'app/main/components/Logo';
import styles from './ResetPassword.module.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link, useParams, useHistory } from 'react-router-dom';
import { resetPassword } from '@fem/cruds/userCrud';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  password: yup.string().max(255).required('Il campo è obbligatorio.'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Le password devono essere le stesse')
    .max(255)
    .required('Il campo è obbligatorio.'),
});

const Login = () => {
  const { code } = useParams();
  const [codeRecovery, setCodeRecovery] = useState('');

  const { mutateAsync, isLoading } = useMutation(resetPassword);

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setCodeRecovery(code);
  }, [code]);

  const validError = (error) => {
    let errors = [];
    error.message.forEach((message) => {
      message.messages.forEach((messages) => {
        errors.push(messages.message);
      });
    });
    getMessagesError(errors);
  };

  const getMessagesError = (errorArray) => {
    let response = '';
    errorArray.forEach((error) => {
      switch (error) {
        case 'Incorrect code provided.':
          response += 'Codice usato o errato fornito.';
          break;
        default:
          response += 'Si è verificato un errore.';
          break;
      }
    });
    toast.error(response);
  };

  const handledSubmit = async ({ password, passwordConfirmation }) => {
    const resetPasswordToast = toast.loading('Cambiare la password...');
    try {
      await mutateAsync({
        password,
        passwordConfirmation,
        code: codeRecovery,
      });
      toast.success('Password cambiata con successo', {
        onClose: () => history.push('/'),
      });
    } catch (e) {
      if (e?.response?.data) {
        validError(e.response.data);
        return;
      }
      toast.error(e?.message || 'Recupero della password non riuscito');
    } finally {
      toast.dismiss(resetPasswordToast);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handledSubmit}
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Logo />
            <Row className={styles.bgTransparent}>
              {isLoading ? (
                <Alert variant="info">Cambiare la password...</Alert>
              ) : (
                <Col className="pt-4 text-center">
                  <div>
                    <h2 className="mb-3">Cambiare la password</h2>
                    <p>Inserisci la tua nuova password</p>
                  </div>
                  <Row className="justify-content-md-center">
                    <Col lg={5} md={8} sm>
                      <Form.Control
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                        className={styles.input}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      <Form.Control
                        placeholder="Conferma Password"
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.passwordConfirmation &&
                          !!errors.passwordConfirmation
                        }
                        className={styles.input}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.passwordConfirmation}
                      </Form.Control.Feedback>
                      <Button type="submit" variant="primary" className="my-3">
                        CAMBIA PASSWORD
                      </Button>

                      <p className="my-2">Ha già la sua password?</p>
                      <Link
                        to="/login"
                        role="button"
                        className="my-3 btn btn-primary "
                      >
                        VAI AL LOGIN
                      </Link>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row className="mt-4 bg-white">
              <Col className="pt-4">
                <h2>{t('reset_password.title', '')}</h2>
                <p>{t('reset_password.subtitle', '')}</p>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Login;
