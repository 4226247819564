import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { postOrdersPaypalPaymentCancel } from '@fem/cruds/orderCrud';
import { useParams } from 'react-router';

const PaypalOrdersCancel = (props) => {
  let location = useLocation();
  const history = useHistory();
  let { id: orderId } = useParams();

  const countOrderTokens = (info) => {
    const params = {
      data: {
        token: info.token,
        order: orderId,
      },
    };
    postOrdersPaypalPaymentCancel(params)
      .then(() => {
        if (orderId) {
          history.push(`/orders/${orderId}`);
        } else {
          history.push('/personal/orders-history');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    countOrderTokens(parsed);
    // eslint-disable-next-line
  }, []);

  return '';
};

export default PaypalOrdersCancel;
