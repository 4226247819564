import React from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import Logo from 'app/main/components/Logo';
import styles from './ForgotPassword.module.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../../../@fem/cruds/userCrud';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Deve essere un emai valido')
    .max(255)
    .required('Il campo è obbligatorio.'),
});

const ForgotPassword = () => {
  const { mutateAsync, isLoading } = useMutation(forgotPassword);

  const { t } = useTranslation();

  const handledSubmit = async ({ email }) => {
    const forgoPasswordToast = toast.loading('Invio di posta di recupero...');
    try {
      await mutateAsync({ email });
      toast.success("È stata inviata un'e-mail per recuperare la tua password");
    } catch (e) {
      toast.error(e?.message || 'Recupero della password non riuscito');
    } finally {
      toast.dismiss(forgoPasswordToast);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handledSubmit}
      initialValues={{
        email: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Logo />
          <Row className={styles.bgTransparent}>
            <Col className="pt-4 text-center">
              {isLoading ? (
                <Alert variant="info">{t('forgot_password.alert', '')}</Alert>
              ) : (
                <>
                  <div>
                    <h2 className="mb-3">{t('forgot_password.title', '')}</h2>
                    <p>{t('forgot_password.text', '')}</p>
                  </div>
                  <Row className="justify-content-md-center">
                    <Col lg={5} md={8} sm>
                      <Form.Control
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                        className={styles.input}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                      <Button type="submit" variant="primary" className="my-3">
                        {t('forgot_password.btn', '')}
                      </Button>

                      <p className="my-2">
                        {t('forgot_password.return_title', '')}
                      </p>
                      <Link
                        to="/login"
                        role="button"
                        className="my-3 btn btn-primary "
                      >
                        {t('forgot_password.return_link', '')}
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-4 bg-white">
            <Col className="pt-4">
              <h2>{t('forgot_password.notices.title', '')}</h2>
              <p>{t('forgot_password.notices.p_1', '')}</p>
              <p>{t('forgot_password.notices.p_2', '')}</p>
              <p className="fw-bold">{t('forgot_password.notices.bold', '')}</p>
              <p>{t('forgot_password.notices.p_3', '')}</p>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
