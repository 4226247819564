import React from 'react';
import styles from './index.module.scss';
import { Col, Row } from 'react-bootstrap';

const SectionTitle = ({ children }) => {
  return (
    <Row className="mb-3">
      <Col sm={12} className={styles.title}>
        {children}
      </Col>
    </Row>
  );
};

export default SectionTitle;
