import axios from 'axios';
import { BASE_URL } from './constants';

export const postNewUser = (data) => {
  return axios.post(`${BASE_URL}/api/auth/local/register`, data);
};

export const postNewClient = (data, config) => {
  return axios.post(`${BASE_URL}/api/clients`, data, config);
};

export const postNewAssociationMemberships = (data) => {
  return axios.post(`${BASE_URL}/api/association-memberships`, data);
};

export const postLogin = (data) => {
  return axios.post(`${BASE_URL}/api/auth/local`, data);
};

export const getClientById = (id) => {
  return axios.get(
    `${BASE_URL}/api/clients/${id}?populate=association_membership.association`,
  );
};

export const updateClientById = (id, data) => {
  return axios.put(`${BASE_URL}/api/clients/${id}`, data);
};

export const updateClientAssociationById = (id, data) => {
  return axios.put(`${BASE_URL}/api/association-memberships/${id}`, data);
};

export const deleteClientAssociationById = (id) => {
  return axios.delete(`${BASE_URL}/api/association-memberships/${id}`);
};

export const resetPassword = (data) => {
  return axios.post(`${BASE_URL}/api/auth/reset-password`, data);
};

export const forgotPassword = (data) => {
  return axios.post(`${BASE_URL}/api/auth/forgot-password`, data);
};
