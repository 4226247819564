import ForgotPassword from './ForgotPassword';
import authRoles from 'app/auth/authRoles';

const ForgotPasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: true,
          personalMenu: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/forgot-password',
      component: ForgotPassword,
    },
  ],
};

export default ForgotPasswordConfig;
