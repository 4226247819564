import LoginConfig from './Login/LoginConfig';
import Error404PageConfig from './errors/404/Error404PageConfig';
import Error500PageConfig from './errors/500/Error500PageConfig';
import RegisterConfig from './Register/RegisterConfig';
import PersonalConfig from './Personal/PersonalConfig';
import HomeConfig from './Home/HomeConfig';
import OrderConfig from './Order/OderConfig';
import OrdersConfig from './Orders/OrdersConfig';
import CertificateConfig from './Certificate/CeritificateConfig';
import ResetPasswordConfig from './ResetPassword/ResetPasswordConfig';
import ForgotPasswordConfig from './ForgotPassword/ForgotPasswordConfig';
import PaypalConfig from './Paypal/PaypalConfig';

const pagesConfigs = [
  LoginConfig,
  Error404PageConfig,
  Error500PageConfig,
  RegisterConfig,
  PersonalConfig,
  HomeConfig,
  OrderConfig,
  OrdersConfig,
  CertificateConfig,
  ResetPasswordConfig,
  ForgotPasswordConfig,
  PaypalConfig,
];

export default pagesConfigs;
