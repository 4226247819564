import React from 'react';
import { Badge, Row, Table } from 'react-bootstrap';
import styles from './index.module.scss';
import SectionSubTitle from '../SectionSubtitle';
import Moment from 'react-moment';
import 'moment/locale/it';
import { default as CS } from 'classnames/bind';
import { Download } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function SectionOrderSamplesTable({ samples, order }) {
  const { t } = useTranslation();

  const analysesResult = (type) => {
    switch (type) {
      case 'POS':
        return t('order_sample_table.analyses_result.positive', '');
      case 'NEG':
        return t('order_sample_table.analyses_result.negative', '');
      case 'F':
        return t('order_sample_table.analyses_result.female', '');
      case 'M':
        return t('order_sample_table.analyses_result.male', '');
      default:
        return '';
    }
  };

  const getSpecie = (other_species, subspecie) => {
    if (other_species && other_species !== '') {
      return other_species;
    }
    const specieName =
      subspecie?.data?.attributes?.specie?.data?.attributes?.name || '';
    const subSpecieName = subspecie?.data?.attributes?.name || '';
    return `${specieName}${subSpecieName !== '' ? ' - ' : ''}${subSpecieName}`;
  };

  const getBadge = (result, sample) => {
    if (!result || result === '') {
      if (sample?.data?.attributes?.lab?.data == null) {
        return <Badge bg="black">{t('order_sample_table.badge', '')}</Badge>;
      }
      return '';
    } else {
      return analysesResult(result);
    }
  };

  return (
    <>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionSubTitle>{t('order_sample_table.title', '')}</SectionSubTitle>
      </Row>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.greenBackground)}>
        <p>
          <span className={'fw-bold'}>
            {t('order_sample_table.p_1.bold', '')}
          </span>
          {t('order_sample_table.p_1.text', '')}
          <span className={'fw-bold'}>
            {t('order_sample_table.p_2.bold', '')}
          </span>
          {t('order_sample_table.p_2.text', '')}
        </p>
        <Row className={'custom-background-pricing-table pl-4'}>
          <Table hover responsive="lg" className={styles.orderTable}>
            <thead>
              <tr>
                <th>{t('order_sample_table.table.identifier', '')}</th>
                <th>{t('order_sample_table.table.specie', '')}</th>
                <th>{t('order_sample_table.table.analisys', '')}</th>
                <th>{t('order_sample_table.table.result', '')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {samples.length > 0 &&
                samples.map(
                  (
                    {
                      createdAt,
                      id,
                      status,
                      identifier,
                      other_species,
                      analyses,
                      subspecie,
                      owner,
                    },
                    index,
                  ) => (
                    <tr key={id}>
                      <td>
                        <b className="me-2">{index + 1}</b>
                        <div className="d-flex flex-column">
                          <i>
                            {console.log('owner', owner)}
                            {owner && owner !== ''
                              ? owner
                              : order.client.data.attributes.company_name}
                          </i>
                          <i>{identifier}</i>
                          <Moment format="LLLL">{createdAt}</Moment>
                        </div>
                      </td>
                      <td>{getSpecie(other_species, subspecie)}</td>
                      <td>
                        {analyses.map(({ analysis_type, id }) => {
                          return (
                            <div key={id}>
                              <span className={styles.textForAnalyse}>
                                {analysis_type}
                              </span>
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        {console.log('analyses', analyses)}
                        {analyses.map(({ sample, result, id }) => {
                          return <div key={id}>{getBadge(result, sample)}</div>;
                        })}
                      </td>
                      <td>
                        {analyses.map(({ result, id }) => {
                          return (
                            <div key={id}>
                              {!result || result === '' ? (
                                ''
                              ) : (
                                <a
                                  href={`/certificate/${id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Badge>
                                    {t(
                                      'order_sample_table.certificate_digital',
                                      '',
                                    )}{' '}
                                    <Download style={{ marginLeft: 10 }} />
                                  </Badge>
                                </a>
                              )}
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </Table>
        </Row>
      </Row>
    </>
  );
}

export default SectionOrderSamplesTable;
