import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
// import { setUserData } from './userSlice';
import { toast } from 'react-toastify';

export const submitRegister =
  (userData, userClient, userAssociation) => async (dispatch) => {
    return toast
      .promise(jwtService.createUser(userData), {
        pending: 'Creazione di un utente',
        success: 'Utente creato con successo 👌',
        error: {
          render({ data }) {
            const {
              response: {
                data: { error },
              },
            } = data;
            switch (error.message) {
              case 'Email is already taken':
                return "L'e-mail è già stata presa";
              default:
                return 'Errore durante la registrazione';
            }
          },
        },
      })
      .then((user) => {
        return toast
          .promise(
            jwtService.createClient(
              {
                data: {
                  ...userClient.data,
                  user: user.id,
                },
              },
              userAssociation,
            ),
            {
              pending: 'Registrandosi',
              success: 'Registrazione di successo 👌',
              error: {
                render({ data }) {
                  const {
                    response: {
                      data: { error },
                    },
                  } = data;
                  switch (error.message) {
                    case 'Invalid identifier or password':
                      return 'Utente o password non validi';
                    default:
                      return 'Errore durante la registrazione';
                  }
                },
              },
            },
          )
          .then((client) => {
            /* dispatch(
              setUserData({
                ...user,
                client: { ...client.attributes, id: client.id },
              }),
            ); */
            return dispatch(registerSuccess());
          })
          .catch((errors) => {
            return dispatch(registerError(errors));
          });
      })
      .catch((errors) => {
        return dispatch(registerError(errors));
      });
  };

const initialState = {
  success: false,
  errors: [],
};

const registerSlice = createSlice({
  name: 'auth/register',
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    registerError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;
