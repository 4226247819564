import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  createAnalysis,
  createOrder,
  createSample,
  postCreateLinkPaypalByOrderId,
  updateOrder,
} from '@fem/cruds/orderCrud';
import { toast } from 'react-toastify';
import { updateClientById } from '@fem/cruds/userCrud';
import { setNewCredits } from 'app/auth/store/userSlice';

export const createNewOrder = createAsyncThunk(
  'order/createOrder',
  async (data, thunkAPI) => {
    const response = await createOrder(data);
    return response.data;
  },
);

export const updateOldOrder = createAsyncThunk(
  'order/updateOrder',
  async (data, thunkAPI) => {
    const response = await updateOrder(data);
    return response.data;
  },
);

export const createNewSample = createAsyncThunk(
  'order/createSample',
  async (data, thunkAPI) => {
    const response = await createSample(data);
    return response.data;
  },
);

export const createNewAnalysis = createAsyncThunk(
  'order/createAnalysis',
  async (data, thunkAPI) => {
    const response = await createAnalysis(data);
    return response.data;
  },
);

export const createNewOrderInLoop =
  (
    source,
    history,
    paymentMethod = null,
    serviceToPay = 0,
    creditsConsumed = 0,
  ) =>
  async (dispatch, getState) => {
    const {
      cart: {
        items,
        total,
        notes,
        commission,
        shipping,
        requiredInvoice,
        genders,
        smapDiscount,
      },
    } = getState().order.order;
    // console.log(getState().order);
    const {
      client: { id: clientId, fem_credit: femCredit },
    } = getState().auth.user.data;
    const createOrderToast = toast.loading('Creare un nuovo ordine');
    try {
      let _paymentMethod = null;
      if (source === 'PAYMENT') {
        if (femCredit && femCredit > 0 && !serviceToPay) {
          _paymentMethod = 'CREDITO';
        } else {
          _paymentMethod = paymentMethod;
        }
      }
      const newTotal = (
        Number(total) +
        Number(commission) +
        Number(shipping)
      ).toFixed(2);

      const newOrder = {
        data: {
          require_invoice: requiredInvoice,
          status: source === 'SUMMARY' ? 'RNS_FEM' : 'PAGAMENTO',
          payment_method: _paymentMethod,
          total_services: newTotal,
          services_to_pay: newTotal,
          amount: newTotal,
          customer_notes: notes,
          client: {
            id: clientId,
          },
        },
      };

      const resultAction = await dispatch(createNewOrder(newOrder));
      const {
        data: { id: orderId },
      } = unwrapResult(resultAction);

      // THIS LOGIC IS ONLY FOR A NORMAL ORDER
      //  WITHOUT UNKNOWN SPECIES
      if (source === 'PAYMENT') {
        const totalAndShipping = Number(total) + shipping;
        const orderToUpdateData = {
          id: orderId,
          data: {
            status:
              femCredit && femCredit > 0 && !serviceToPay
                ? 'MATERIALE'
                : 'PAGAMENTO',
            use_credits: false,
            credits_consumed: 0,
            services_to_pay: Number(totalAndShipping),
            amount: Number(totalAndShipping) + Number(commission),
            total_services: Number(totalAndShipping),
          },
        };

        if (femCredit && femCredit > 0) {
          const amount = Number(serviceToPay) + Number(commission);
          Object.assign(orderToUpdateData.data, {
            use_credits: true,
            credits_consumed: Number(creditsConsumed),
            services_to_pay: Number(serviceToPay),
            amount: amount,
            total_services: Number(creditsConsumed) + Number(serviceToPay),
            payment_method: amount > 0 ? paymentMethod : 'CREDITO',
          });

          const restOfFemCredits = Number(femCredit) - Number(creditsConsumed);
          const creditToUpdate = {
            data: { fem_credit: restOfFemCredits },
          };
          await updateClientById(clientId, creditToUpdate);
          await dispatch(setNewCredits(restOfFemCredits));
        }

        await dispatch(updateOldOrder(orderToUpdateData));
      }

      // const results = await Promise.all(
      //   items.map((sample) => {
      //     console.log('sample', sample);
      //     let gender = null;
      //     if (!sample.specieId && genders.length > 0) {
      //       const sampleGender = genders.find(
      //         (gender) => gender.specie === sample.specie,
      //       );
      //       gender = sampleGender?.sesso || null;
      //     }
      //     return dispatch(
      //       createNewSample({
      //         data: {
      //           standard: sample.specieId ? null : gender,
      //           other_species: sample.specieId ? null : sample.specie,
      //           subspecie: sample.specieId,
      //           identifier: sample.patientInfo.description,
      //           mutation: sample.patientInfo.mutation,
      //           owner: sample.patientInfo.birthDate.owner,
      //           date_of_birth: sample.patientInfo.birthDate || null,
      //           order: orderId,
      //         },
      //       }),
      //     );
      //   }),
      // );
      let results = [];
      for (let sample of items) {
        let gender = null;
        if (!sample.specieId && genders.length > 0) {
          const sampleGender = genders.find(
            (gender) => gender.specie === sample.specie,
          );
          gender = sampleGender?.sesso || null;
        }
        const newSample = await dispatch(
          createNewSample({
            data: {
              standard: sample.specieId ? null : gender,
              other_species: sample.specieId ? null : sample.specie,
              subspecie: sample.specieId,
              identifier: sample.patientInfo.description,
              mutation: sample.patientInfo.mutation,
              owner: sample.patientInfo.owner,
              date_of_birth: sample.patientInfo.birthDate || null,
              order: orderId,
            },
          }),
        );
        results.push(newSample);
      }

      for (const result of results) {
        const index = results.indexOf(result);
        const {
          data: { id: sampleId },
        } = unwrapResult(result);
        await Promise.all(
          items[index].analyses.map((analyse) => {
            const analyseType =
              analyse?.key === 'clamidia' ? 'CLAM' : analyse.key;
            let price = analyse.price;
            if(analyseType.toUpperCase() === 'SMAP' && smapDiscount.price > 0) {
              price = Number(smapDiscount.price);
            }
            return dispatch(
              createNewAnalysis({
                data: {
                  sample: sampleId,
                  analysis_type: analyseType.toUpperCase(),
                  refundable: true,
                  price: price,
                  certificate_paper: analyse.print.value,
                  certificate_digital: analyse.digital.value,
                },
              }),
            );
          }),
        );
      }

      toast.success('Ordine creato con successo 👌');
      if (_paymentMethod === 'PPAL') {
        const { data } = await postCreateLinkPaypalByOrderId({
          orderId,
        });
        if (data?.linkPaypal) {
          window.location.href = data.linkPaypal;
        } else {
          toast.error(
            'Si è verificato un errore durante il pagamento con paypal, riprovare più tardi.',
          );
        }
      } else {
        history.push(`/orders/${orderId}`);
      }
      dispatch(resetCart());
      dispatch(removeWaitingToAuthorizationStep());
    } catch (e) {
      console.error(e);
      toast.error("Errore durante la creazione dell'ordine");
    } finally {
      toast.dismiss(createOrderToast);
    }
  };

const initialState = {
  cart: {
    subTotal: 0,
    discount: 0,
    total: 0,
    commission: 0,
    orderId: 0,
    shipping: 0,
    items: [],
    notes: '',
    genders: [],
    requiredInvoice: false,
    smapDiscount: {
      price: 0,
      total: 0
    },
  },
  steps: [
    {
      label: 'Carrello',
      active: true,
      activeIconPath: '/assets/icons/local_grocery_store_24px_outlined.svg',
      inactiveIconPath: '/assets/icons/local_grocery_store_24px.svg',
      activeBgColor: '#eea236',
      inactiveBgColor: '#F3EDE2',
      completedBgColor: '#F3EDE2',
    },
    {
      label: 'Riepilogo',
      active: false,
      activeIconPath: '/assets/icons/view_list_24px.svg',
      inactiveIconPath: '/assets/icons/view_list_active_24px.svg',
      activeBgColor: '#eea236',
      inactiveBgColor: '#F3EDE2',
      completedBgColor: '#F3EDE2',
    },
    {
      label: 'Pagamento',
      active: false,
      activeIconPath: '/assets/icons/euro.svg',
      inactiveIconPath: '/assets/icons/euro.svg',
      activeBgColor: '#eea236',
      inactiveBgColor: '#F3EDE2',
      completedBgColor: '#F3EDE2',
    },
    {
      label: 'Conferma',
      active: false,
      activeIconPath: '/assets/icons/check_24px.svg',
      inactiveIconPath: '/assets/icons/check_24px.svg',
      activeBgColor: '#BFDCD3',
      inactiveBgColor: '#F3EDE2',
      completedBgColor: '#F3EDE2',
    },
  ],
  stepsRoutes: ['cart', 'summary', 'payment', 'success'],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetCart: (state) => {
      state.cart = { ...initialState.cart };
    },
    addSample: (state, action) => {
      state.cart = {
        ...state.cart,
        items: [...state.cart.items, action.payload],
      };
    },
    removeSample: (state, action) => {
      const item = state.cart.items.find((item) => item.id === action.payload);

      state.cart = {
        ...state.cart,
        items: [
          ...state.cart.items.filter((item) => item.id !== action.payload),
        ],
        total: state.cart.total - item.totalWithDiscount,
        discount: state.cart.discount - item.totalOfDiscount,
        subTotal: state.cart.subTotal - item.totalWithoutDiscount,
      };
    },
    applySmapDiscount: (state, action) => {
      state.cart = {
        ...state.cart,
        smapDiscount: {
          price: action.payload.price,
          total: action.payload.total
        },
        discount: state.cart.discount - state.cart.smapDiscount.total + action.payload.total,
        total: state.cart.total + state.cart.smapDiscount.total - action.payload.total,
      };
    },
    removeSmapDiscount: (state) => {
      state.cart = {
        ...state.cart,
        smapDiscount: {
          price: 0,
          total: 0
        },
        discount: state.cart.discount - state.cart.smapDiscount.total,
        total: state.cart.total + state.cart.smapDiscount.total,
      };
    },
    updateIdentifierBySampleId: (state, action) => {
      const index = state.cart.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      state.cart = {
        ...state.cart,
        items: [
          ...state.cart.items.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                patientInfo: {
                  ...item.patientInfo,
                  description: action.payload.identifier,
                },
              };
            }
            return item;
          }),
        ],
      };
    },
    addElementToCart: (state, action) => {
      state.cart = action.payload;
    },
    addNotesToCart: (state, action) => {
      state.cart = { ...state.cart, notes: action.payload };
    },
    addCommissionToCart: (state, action) => {
      state.cart = { ...state.cart, commission: action.payload };
    },
    addShippingToCart: (state, action) => {
      state.cart = { ...state.cart, shipping: action.payload };
    },
    addInvoiceToCart: (state, action) => {
      state.cart = { ...state.cart, requiredInvoice: action.payload };
    },
    addGenderToCart: (state, action) => {
      state.cart = {
        ...state.cart,
        genders: [...state.cart.genders, action.payload],
      };
    },
    removeGenderFromCart: (state, action) => {
      state.cart = {
        ...state.cart,
        genders: [
          ...state.cart.genders.filter(
            (gender) => gender.id !== action.payload,
          ),
        ],
      };
    },
    addWaitingToAuthorizationStep: (state) => {
      if (state.steps.some((step) => step.label === 'Attesa')) return;
      state.steps = [
        ...state.steps.slice(0, 2),
        {
          label: 'Attesa',
          active: false,
          activeIconPath: '/assets/icons/check_24px.svg',
          inactiveIconPath: '/assets/icons/check_24px.svg',
          activeBgColor: '#BFDCD3',
          inactiveBgColor: '#F3EDE2',
          completedBgColor: '#F3EDE2',
        },
        ...state.steps.slice(2),
      ];
    },
    removeWaitingToAuthorizationStep: (state, action) => {
      state.steps = [...state.steps.filter((step) => step.label !== 'Attesa')];
    },
  },
});

export const {
  addSample,
  removeSample,
  addElementToCart,
  addNotesToCart,
  addGenderToCart,
  removeGenderFromCart,
  addWaitingToAuthorizationStep,
  resetCart,
  removeWaitingToAuthorizationStep,
  addCommissionToCart,
  addShippingToCart,
  addInvoiceToCart,
  updateIdentifierBySampleId,
  applySmapDiscount,
  removeSmapDiscount
} = orderSlice.actions;

export default orderSlice.reducer;
