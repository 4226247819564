import React, { useState } from 'react';
import styles from './Certificate.module.scss';
import { getCertificate } from '@fem/cruds/certificateCrud';
import { Image } from 'react-bootstrap';
import { PrinterFill } from 'react-bootstrap-icons';
import { BASE_URL } from './../../../../@fem/cruds/constants';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';

const getCertificateImage = (type) => {
  switch (type) {
    case 'SMAP':
      return 'sma';
    case 'SMAR':
      return 'sma';
    case 'SMAU':
      return 'sma';
    case 'CLAM':
      return 'clamidia';
    case 'BFDV':
      return 'bfdv';
    case 'APV':
      return 'apv';
    default:
      return '';
  }
};

const analysesResult = (type) => {
  switch (type) {
    case 'POS':
      return 'POSITIVO';
    case 'NEG':
      return 'NEGATIVO';
    case 'F':
      return 'FEMMINA';
    case 'M':
      return 'MASCHIO';
    default:
      return '';
  }
};

const europeanDate = (dateString) => {
  var date = new Date(dateString);
  return date.toLocaleDateString('it');
};

const getOwner = (sample, client) => {
  if (sample.owner) {
    return sample.owner;
  }
  return client.company_name;
};

const Certificate = () => {
  let { code } = useParams();
  const [data, setData] = useState();
  const history = useHistory();

  if (!data) {
    getCertificate(code)
      .then(function (response) {
        setData(response);
      })
      .catch(() => {
        history.push('/');
      });
  }

  if (!data) {
    return null;
  }

  let analysesData = data.data.data.attributes;
  let sampleData = analysesData.sample.data.attributes;
  let specieData = sampleData?.subspecie?.data?.attributes;
  let orderData = sampleData.order.data.attributes;
  let clientData = orderData.client.data.attributes;

  const getSpecieName = () => {
    const specie = specieData?.specie?.data?.attributes?.name;
    const subSpecie = specieData?.name;
    if (specie !== '') {
      return `${specie}${subSpecie !== '' ? ' - ' : ''}${subSpecie}`;
    }
    return specieData.other_species;
  };

  return (
    <div className={styles.certificateExternalContainer}>
      <button
        className={styles.certificatePrintButton}
        onClick={() => window.print()}
      >
        <PrinterFill size={30} className="m-3" />
      </button>

      <div
        className={styles.certificateContainer}
        style={{
          backgroundImage: `url("/assets/certificate/${getCertificateImage(
            analysesData.analysis_type,
          )}.png")`,
        }}
      >
        <div className={styles.certificateOwner}>
          {getOwner(sampleData, clientData)}
        </div>
        <div className={styles.certificateSubject}>{sampleData.identifier}</div>
        <div className={styles.certificateSpecie}>{getSpecieName()}</div>
        <div className={styles.certificateMutation}>
          {sampleData.mutation === '' ? '/' : sampleData.mutation}
        </div>
        <div className={styles.certificateBorn}>
          {sampleData.date_of_birth
            ? europeanDate(sampleData.date_of_birth)
            : '/'}
        </div>
        <div className={styles.certificateDate}>
          {europeanDate(analysesData.updatedAt)}
        </div>
        <div className={styles.certificateLogo}>
          {clientData.certificate_logo.data ? (
            <Image
              className={styles.certificateLogoImage}
              src={BASE_URL + clientData.certificate_logo.data.attributes.url}
            />
          ) : null}
        </div>
        <div className={styles.certificateResult}>
          {analysesResult(analysesData.result)}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
