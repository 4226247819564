import axios from 'axios';
import { BASE_URL } from './constants';

export const getAssociations = () => {
  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` },
  // };
  return axios.get(`${BASE_URL}/api/associations`);
};

// export const postNewAddress = (data) => {
//   const token = localStorage.getItem("token");
//   const config = {
//     headers: { Authorization: `Bearer ${token}` },
//   };
//   return axios.post(`${BASE_URL}/direcciones/me`, data, config);
// };
