import React, { useRef, useState, useEffect } from 'react';
import styles from './PackageCredits.module.scss';
import { default as CS } from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import useToggle from '@fem/hooks/useToggle';
import SectionTitle from '../../../components/SectionTitle';
import {
  Badge,
  Button,
  Col,
  Image,
  Row,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import SectionSubTitle from '../../../components/SectionSubtitle';
import PricingTable from '../../../components/PricingTable';
import { useHistory } from 'react-router-dom';
import {
  getPackages,
  getUserPackages,
  postUserPackages,
  postUserPackagesPaypal,
} from '@fem/cruds/creditCrud';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);
const CreditStatus = {
  WAITING_FOR_PAYMENT: 1,
  WAITING_FOR_ACCREDITED: 2,
  ACCEPT: 3,
};
const orderStatusToText = {
  [CreditStatus.WAITING_FOR_PAYMENT]: 'In attesa di Pagamento',
  [CreditStatus.WAITING_FOR_ACCREDITED]: 'In attesa di Accreditato',
  [CreditStatus.ACCEPT]: 'Accreditati',
};
const initialValueForPaymentMethod = 'BONB';
const PackageCredits = () => {
  const wrapperStyles = classNames('p-2 p-md-3 p-lg-4', styles.wrapper);
  const [priceSelected, setPriceSelected] = useState(undefined);
  const [paymentMethod, setPaymentMethod] = useState(
    initialValueForPaymentMethod,
  );
  const {
    client: { fem_credit, company_name },
  } = useSelector(({ auth }) => auth.user.data);

  // const [rowPaymentMethodId, setRowPaymentMethodId] = useState(null);
  const form = useRef(null);
  const [packages, setPackages] = useState([]);
  const [prices, setPrices] = useState([]);
  const [isOpen, setIsOpen] = useToggle(false);
  const [methodPaymentForModal, setMethodPaymentForModal] = useState(false);
  const [currentPackage, setCurrentPackage] = useState({
    price: 0,
    comission: 0,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  let history = useHistory();

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    if (!currentPackage.price) {
      return;
    }
    const comission = getComissionByPaymentMethod({
      paymentMethod: e.target.value,
      price: currentPackage.price,
    });

    setCurrentPackage({
      ...currentPackage,
      comission,
      total: currentPackage.price + comission,
    });
  };

  const resetAllFields = () => {
    setPriceSelected(undefined);
    setMethodPaymentForModal(false);
    setPaymentMethod(initialValueForPaymentMethod);
    form.current.reset();
  };

  const fieldsHasError = () => {
    let hasErrors = false;
    if (!priceSelected) {
      toast.error('Non è stato selezionato alcun pacchetto.');
      hasErrors = true;
    }
    if (!paymentMethod) {
      toast.error("E' necessario selezionare il tipo di pagamento.");
      hasErrors = true;
    }

    return hasErrors;
  };

  const handleClickCreditRow = (paymentMethod) => {
    setMethodPaymentForModal(true);
    setPaymentMethod(paymentMethod);
    setIsOpen(true);
  };

  const getComissionByPaymentMethod = ({ paymentMethod, price }) => {
    if (paymentMethod === 'PPAL') {
      return price * 0.04 + 0.35;
    }
    if (paymentMethod === 'BOLP') {
      return 0.5;
    }
    if (paymentMethod === 'BONB') {
      return 0;
    }
  };

  const handleClickAddCredits = () => {
    //CALL API
    const data = {
      data: {
        amount: priceSelected.price + currentPackage.comission,
        credit: priceSelected.credits,
        paid: false,
        accredited: false,
        payment_method: paymentMethod,
        package: priceSelected.id,
      },
    };
    postUserPackages(data)
      .then((response) => {
        const data = response.data.data;
        setPackages([
          ...packages,
          {
            data: data.attributes.updatedAt,
            creditId: data.id,
            status: 1,
            credit: `${data.attributes.credit} crediti`,
            paymentMethod: data.attributes.paymentMethod,
          },
        ]);
        toast.success('Congratulazioni, ordine effettuato.');
        handleCloseModal();
        resetAllFields();
      })
      .catch((error) => {});
  };

  const validPurchase = () => {
    if (fem_credit > 0) {
      toast.error(
        'Non puoi comprare un altro pacchetto finché hai crediti disponibili.',
      );
      return;
    }
    if (fieldsHasError()) return;
    setIsOpen(true);
  };

  useEffect(() => {
    const getPackageData = async () => {
      const response = await getPackages();
      const result = await response.data.data;
      const packageData = result.map((item) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      setPrices(packageData);
    };

    const getStatus = (item) => {
      const {
        attributes: { paid, accredited },
      } = item;

      if (paid && accredited) {
        return 3;
      } else if (paid && !accredited) {
        return 2;
      } else {
        return 1;
      }
    };

    const getUserPackageData = async () => {
      const response = await getUserPackages();
      const result = await response.data.data;
      const packageData = result.map((item) => {
        const {
          id,
          attributes: {
            updatedAt,
            amount,
            paid,
            accredited,
            credit,
            payment_method,
          },
        } = item;
        return {
          id: id,
          data: updatedAt,
          amount: amount,
          creditId: id,
          paid: paid,
          accredited: accredited,
          status: getStatus(item),
          credit: `${credit} crediti`,
          paymentMethod: payment_method,
        };
      });

      setPackages(packageData);
    };

    getPackageData();
    getUserPackageData();
  }, []);

  const getCurrentPackage = (data) => {
    setPriceSelected(data);
    const comission = getComissionByPaymentMethod({
      paymentMethod,
      price: data.price,
    });

    setCurrentPackage({
      price: data.price,
      comission,
      total: data.price + comission,
    });
  };

  const sendToPaypal = () => {
    if (isLoading) return;
    const data = {
      packageId: priceSelected.id,
      commission: currentPackage.comission ? currentPackage.comission : 0,
    };
    setIsLoading(true);
    postUserPackagesPaypal(data)
      .then((response) => {
        const data = response.data;
        window.location.href = data.linkPaypal;
      })
      .catch((error) => {});
  };

  const { t } = useTranslation();

  return (
    <Row className={wrapperStyles}>
      <Modal show={isOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('packages.modal.title', '')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line' }}>
          {paymentMethod === 'BONB' && (
            <>
              <p>{t('packages.modal.transfer.title', '')}</p>
              <br />
              {t('packages.modal.transfer.subtitle', '')}
              <em>{t('packages.modal.transfer.footer', '')}</em>
            </>
          )}
          {paymentMethod === 'BOLP' && (
            <>
              <p>{t('packages.modal.postal.title', '')}</p>
              <br />
              {t('packages.modal.postal.subtitle', '')}
            </>
          )}
          {paymentMethod === 'PPAL' && (
            <>
              <p>{t('packages.modal.paypal.title', '')}</p>
              <br />
              {t('packages.modal.paypal.subtitle', '')}{' '}
              <a
                href="http://ordiniavifauna.fem2ambiente.com/static/links/Paypal.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('packages.modal.paypal.subtitle_link', '')}
              </a>
              <Image
                className={classNames('paypalImg', { disabled: isLoading })}
                src="/assets/paypal-logo.png"
                onClick={sendToPaypal}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={
              methodPaymentForModal ? handleCloseModal : handleClickAddCredits
            }
            disabled={isLoading}
          >
            {paymentMethod === 'PPAL' ? (
              <>{t('packages.modal.save_btn.later', '')}</>
            ) : (
              <>{t('packages.modal.save_btn.ok', '')}</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <SectionTitle>{t('packages.title', '')}</SectionTitle>
      <p>{t('packages.subtitle', { name: company_name }, '')}</p>
      <Row className="mt-4">
        <Col>
          <Button
            className="me-2 me-md-4"
            onClick={() => history.push('/order/cart')}
          >
            {t('packages.new_order_btn', '')}
            <Image
              width="20px"
              height="20px"
              className="img-fluid ms-2"
              src={'/assets/icons/local_mall_24px_outlined.svg'}
            />
          </Button>
          <Button disabled variant="secondary">
            {fem_credit} €
          </Button>
        </Col>
      </Row>
      <SectionSubTitle icon="/assets/icons/rotate_right_24px_outlined.svg">
        {t('packages.table.title', '')}
      </SectionSubTitle>
      <Row className="mt-2">
        <Col sm={10} lg={8}>
          <Table hover size="sm">
            <thead>
              <tr>
                <th>{t('packages.table.date', '')}</th>
                <th>{t('packages.table.package', '')}</th>
                <th>{t('packages.table.status', '')}</th>
              </tr>
            </thead>
            <tbody>
              {packages &&
                packages.length > 0 &&
                packages.map(({ id, data, paymentMethod, status, credit }) => {
                  const badgeStyle = () => {
                    switch (status) {
                      case CreditStatus.WAITING_FOR_PAYMENT:
                        return 'warning';
                      case CreditStatus.ACCEPT:
                        return 'success';
                      default:
                        return 'info';
                    }
                  };
                  return (
                    <tr
                      role="button"
                      key={`item_package_${id}`}
                      onClick={() => handleClickCreditRow(paymentMethod)}
                    >
                      <td>{data}</td>
                      <td>{credit}</td>
                      <td>
                        <Badge bg={badgeStyle()}>
                          {orderStatusToText[status]}
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <SectionSubTitle icon="/assets/icons/play_arrow_24px_outlined.svg">
        {t('packages.list.title', '')}
      </SectionSubTitle>
      <PricingTable
        prices={prices}
        getSelectedPrice={getCurrentPackage}
        currentPrice={priceSelected}
      />
      <Row>
        <Col>
          <SectionSubTitle>{t('packages.summary.title', '')}</SectionSubTitle>
          <Table hover size="sm">
            <tbody>
              <tr>
                <td>{t('packages.summary.service', '')}</td>
                <td>€{currentPackage.price.toFixed(2)}</td>
              </tr>
              <tr>
                <td>{t('packages.summary.commission', '')}</td>
                <td>€{currentPackage.comission.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <b>{t('packages.summary.total', '')}</b>
                </td>
                <td>
                  <b>€{currentPackage.total.toFixed(2)}</b>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <SectionSubTitle>{t('packages.methods.', '')}</SectionSubTitle>
          <Form ref={form}>
            <Form.Check
              type="radio"
              name="paymentType"
              value={'BONB'}
              label={t('packages.methods.transfer', '')}
              checked={paymentMethod === 'BONB'}
              onChange={handleChangePaymentMethod}
            />
            <Form.Check
              type="radio"
              name="paymentType"
              value={'BOLP'}
              checked={paymentMethod === 'BOLP'}
              label={t('packages.methods.postal', '')}
              onChange={handleChangePaymentMethod}
            />
            <Form.Check
              type="radio"
              name="paymentType"
              value={'PPAL'}
              label={t('packages.methods.paypal', '')}
              checked={paymentMethod === 'PPAL'}
              onChange={handleChangePaymentMethod}
            />
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={6} className={styles.terms}>
          <p>
            {t('packages.terms.terms', '')}{' '}
            <NavLink to="/terms-and-conditions">
              {t('packages.terms.terms_link', '')}
            </NavLink>
          </p>
          <p>
            {t('packages.terms.conditions', '')}{' '}
            <NavLink to="/privacy">
              {t('packages.terms.conditions_links', '')}
            </NavLink>
          </p>
        </Col>
        <Col sm={6} className="d-grid gap-2 mx-auto p-4">
          <Button
            type="button"
            onClick={() => {
              validPurchase();
            }}
          >
            {t('packages.save_btn', '')}
            <Image
              width="20px"
              height="20px"
              className="img-fluid ms-2"
              src={'/assets/icons/local_mall_24px_outlined.svg'}
            />
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default PackageCredits;
