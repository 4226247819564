import authRoles from 'app/auth/authRoles';
import PaypalOrdersCancel from './Orders/Cancel';
import PaypalOrders from './Orders/Orders';
import PaypalPackages from './Packages/Packages';

const PaypalConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: false,
          personalMenu: true,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/paypal/credits/success',
      component: PaypalPackages,
    },
    {
      path: '/paypal/orders/success',
      component: PaypalOrders,
    },
    {
      path: '/paypal/orders/cancel/:id',
      component: PaypalOrdersCancel,
    },
  ],
};

export default PaypalConfig;
