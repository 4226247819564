import Order from './Order';
import authRoles from 'app/auth/authRoles';

const OrderConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: false,
          personalMenu: true,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/order',
      component: Order,
    },
  ],
};

export default OrderConfig;
