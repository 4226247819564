import React from 'react';
import { default as CS } from 'classnames/bind';
import styles from './index.module.scss';
import { Row } from 'react-bootstrap';
import SectionSubTitle from '../SectionSubtitle';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function SectionOrderCommunications({ orderId }) {
  const { t } = useTranslation();

  return (
    <>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionSubTitle>{t('order_communications.title', '')}</SectionSubTitle>
      </Row>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.greenBackground)}>
        <p>{t('order_communications.p_1', '')}</p>
        <p>{t('order_communications.p_2', '')}</p>
        <p>{t('order_communications.p_3', '')}</p>
        <p>{t('order_communications.p_4', '')}</p>
        <p>{t('order_communications.p_5', '')}</p>
        <p className={'fw-bold'}>{t('order_communications.p_6', '')}</p>
        <p>
          <span className={'fw-bold'}>
            {t('order_communications.p_7.title', '')}
          </span>{' '}
          <br />
          {t('order_communications.p_7.subtitle', '')}
        </p>
        <div className={'mb-5'}>
          <p>
            <span className={'fw-bold'}>
              {t('order_communications.p_8.title', '')}
            </span>
            <br />
            {t('order_communications.p_8.subtitle', '')}{' '}
            <a target="_blank" href="https://avifauna.fem2ambiente.com/cosa-spedire-2" rel="noreferrer">
              {t('order_communications.p_8.link', '')}
            </a>{' '}
            {t('order_communications.p_8.footer', '')}
          </p>
        </div>
        <p>
          {t('order_communications.p_9.text', '')}{' '}
          <NavLink to={`/orders/${orderId}`}>
            {t('order_communications.p_9.link', '')}
            {orderId}
          </NavLink>
        </p>
        <p>
          {t('order_communications.p_10.title', '')}
          <span className={'fw-bold'} />{' '}
          {t('order_communications.p_10.subtitle', '')}
        </p>
        <p>{t('order_communications.p_11', '')}</p>
        <p>{t('order_communications.p_12', '')}</p>
        <p className={'fw-italic'}>{t('order_communications.p_13', '')}</p>
      </Row>
    </>
  );
}

export default SectionOrderCommunications;
