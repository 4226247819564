import React from 'react';
import styles from './index.module.scss';
import { Col, Image, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

const SectionLabel = ({ children, icon, prefixIcon }) => {
  const _classNames = classNames.bind(styles);
  const SectionLabelStyles = _classNames(
    'd-flex',
    { 'align-items-center': icon },
    styles.lightSubTitle,
  );
  return (
    <Row>
      <Col sm={12} className={SectionLabelStyles}>
        {icon && prefixIcon && (
          <Image
            className={`img-fluid ${prefixIcon ? 'me-2' : 'ms-2'}`}
            src={icon}
          />
        )}
        {children}
        {icon && !prefixIcon && <Image className="img-fluid ms-2" src={icon} />}
      </Col>
    </Row>
  );
};

export default SectionLabel;
