import React from 'react';
import { Redirect } from 'react-router-dom';
import FemUtils from '@fem/utils';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import modulesConfigs from 'app/main/modules/modulesConfigs';

const routeConfigs = [...modulesConfigs, ...pagesConfigs];

const routes = [
  ...FemUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/personal" />,
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />,
  },
];

export default routes;
