import Home from './Home';

const HomeConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: true,
          personalMenu: true,
        },
      },
    },
  },
  routes: [
    {
      path: '/home',
      component: Home,
    },
  ],
};

export default HomeConfig;
