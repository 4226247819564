import React from 'react';
import { Button, Col, Form, Image, Row, Table } from 'react-bootstrap';
import { default as CS } from 'classnames/bind';
import SectionSubTitle from 'app/main/components/SectionSubtitle';
import { Formik } from 'formik';
import * as yup from 'yup';
import styles from './Summary.module.scss';
import SectionCart from 'app/main/components/SectionCart';
import { useSelector, useDispatch } from 'react-redux';
import {
  addGenderToCart,
  addNotesToCart,
  createNewOrderInLoop,
  removeGenderFromCart,
} from '../store/orderSlice';
import { useHistory } from 'react-router';

const classNames = CS.bind(styles);
const schema = yup.object().shape({
  notes: yup.string(),
  gender: yup.string().required('Il campo è obbligatorio.'),
  genderSpecie: yup.string().required('Il campo è obbligatorio.'),
  genderIdentifier: yup.string().required('Il campo è obbligatorio.'),
});

const Summary = ({ handleNextStep, handlePrevStep }) => {
  const {
    cart,
    cart: { items, genders },
  } = useSelector(({ order }) => order.order);
  const { full_name } = useSelector(({ auth }) => auth.user.data);
  const hasNoRegisterSpecie = items.some((items) => items?.hasOtherSpecie);
  const dispatch = useDispatch();
  const history = useHistory();
  const formatPatientTableRows = (genders) => {
    const rows = [];
    genders.forEach((_patient, index) => {
      rows.push(
        <tr key={_patient.id} className={classNames(styles.noBorder)}>
          <td className={classNames(styles.verticalAlignment)}>
            <span className={'fw-bold'}>{index + 1}</span>
          </td>
          <td>
            <p>
              <span className={'fw-bold'}>{_patient.patientInfo.name} </span>{' '}
              <br />
              {_patient.patientInfo.description} <br />
              {_patient.patientInfo.birthDate}
            </p>
          </td>
          <td>{_patient.specie}</td>
          <td>{_patient.sesso}</td>
          <td className={classNames(styles.verticalAlignment)}>
            <Button
              variant="primary"
              className={classNames(styles.deleteButton)}
              onClick={() => dispatch(removeGenderFromCart(_patient.id))}
            >
              <Row>
                <Col sm={12}>
                  <Image
                    className={'img-fluid'}
                    src={'/assets/icons/close_24px.svg'}
                    onMouseLeave={(e) =>
                      (e.currentTarget.src = '/assets/icons/close_24px.svg')
                    }
                    onMouseOver={(e) =>
                    (e.currentTarget.src =
                      '/assets/icons/close_white_24px.svg')
                    }
                  />
                </Col>
                <Col sm={12}>
                  <span>Elimina</span>
                </Col>
              </Row>
            </Button>
          </td>
        </tr>,
      );
    });
    return rows;
  };
  const handleClickNextStep = async (notes) => {
    dispatch(addNotesToCart(notes));

    if (!hasNoRegisterSpecie) {
      handleNextStep();
      return;
    }
    dispatch(createNewOrderInLoop('SUMMARY', history));
  };

  return (
    <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
      <SectionSubTitle>RIEPILOGO</SectionSubTitle>
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          notes: cart.notes,
          gender: 'Maschio',
          genderSpecie: '',
          genderIdentifier: '',
        }}
        onSubmit={(values, actions) => {
          const newGender = {
            patientInfo: {
              name: full_name,
              description: values.genderIdentifier,
            },
            specie: values.genderSpecie,
            sesso: values.gender,
            id: new Date().getTime(),
          };
          dispatch(addGenderToCart(newGender));
          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row
                className={classNames(styles.greenBackground, styles.topShadow)}
              >
                <Col
                  sm={12}
                  md={10}
                  className={'px-2 px-md-3 px-lg-4 pt-2 pt-md-3 pt-lg-4'}
                >
                  <p>
                    Clicca <a
                      href="https://avifauna.fem2ambiente.com/cosa-spedire-2/"
                      target="_blank"
                      rel='noreferrer'
                    >qui</a> per visualizzare le
                    istruzioni per il prelievo dei campioni.
                  </p>
                  <p>
                    ATTENZIONE! Per snellire le operazioni di valutazione e
                    registrazione dei campioni da parte dei tecnici di
                    laboratorio, e ridurre i tempi di attesa dei risultati, E’
                    NECESSARIO riportare sulla bustina contente il singolo
                    campione oltre al relativo identificativo ANCHE il numero
                    sequenziale di inserimento come riportato nella lista di
                    riepilogo sottostante. Tale numero è riportato nella prima
                    colonna della tabella.
                  </p>
                </Col>
                <Col sm={12} md={2}>
                  <Image src={'/assets/analysis.png'} />
                </Col>
              </Row>
              <SectionCart summary={cart} isBgTransparent isHideDeleteButton />
              {hasNoRegisterSpecie && (
                <>
                  <Row
                    className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}
                  >
                    <Col sm={12}>
                      <SectionSubTitle>CAMPIONI STANDARD</SectionSubTitle>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'px-2 px-md-3 px-lg-4 pt-2 pt-md-3 pt-lg-4',
                      styles.greenBackground,
                      styles.topShadow,
                    )}
                  >
                    <Col sm={12}>
                      Nell’ordine sono stati inseriti uno o più campioni di{' '}
                      <span className={'fw-bold'}>specie non presenti</span> nei
                      nostri database (specie Nuova). In questi casi è
                      indispensabile fornirci anche piume di soggetti STANDARD
                      certi (un maschio e una femmina) per poter effettuare
                      l’analisi e garantire la certezza del risultato.
                      <span className={'fw-bold'}>
                        I campioni standard saranno analizzati gratuitamente.
                      </span>
                    </Col>
                    <Col sm={{ span: 6, offset: 3 }} className={'mt-5'}>
                      <p>
                        Se sei in possesso di soggetti standard, di cui conosci
                        il sesso, inviaci le piume e compila il box sottostante
                        con le informazioni richieste.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'p-2 p-md-3 p-lg-4',
                      styles.greenBackground,
                    )}
                  >
                    <Col md={{ span: 3, offset: 3 }} sm={12}>
                      <Row>
                        <Col sm={1}>
                          <Image
                            src={'/assets/icons/contact_support_24px.svg'}
                          />
                        </Col>
                        <Col sm={11}>
                          <Form.Select
                            aria-label="Seleziona la specia"
                            value={values.genderSpecie}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.genderSpecie && !!errors.genderSpecie
                            }
                            name="genderSpecie"
                          >
                            <option value="" label="Selezionare la Specie" />
                            {items
                              .filter((item) => item?.hasOtherSpecie)
                              .map((item, key) => {
                                return (
                                  <option key={key} value={item?.specie}>
                                    {item?.specie}
                                  </option>
                                );
                              })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.genderSpecie}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 3 }} sm={12}>
                      <Row>
                        <Col sm={1}>
                          <Image
                            src={'/assets/icons/contact_support_24px.svg'}
                          />
                        </Col>
                        <Col sm={11}>
                          <Form.Control
                            placeholder="Identificativo"
                            type="text"
                            name="genderIdentifier"
                            value={values.genderIdentifier}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.genderIdentifier &&
                              !!errors.genderIdentifier
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.genderIdentifier}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'p-2 p-md-3 p-lg-4',
                      styles.greenBackground,
                    )}
                  >
                    <Col
                      md={{ span: 3, offset: 3 }}
                      sm={12}
                      className={'d-flex align-items-md-center'}
                    >
                      <Row className={'d-flex'}>
                        <Col sm={6}>
                          <Form.Check
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="gender"
                            type="radio"
                            label="Maschio"
                            value="Maschio"
                            checked={values.gender === 'Maschio'}
                          />
                        </Col>
                        <Col sm={6}>
                          <Form.Check
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="gender"
                            type="radio"
                            label="Femmina"
                            value="Femmina"
                            checked={values.gender === 'Femmina'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 3 }} sm={12}>
                      <Row>
                        <Col sm={12} className={'d-flex justify-content-end'}>
                          <Button type="submit">
                            Aggiungi Standard
                            <Image
                              width="20px"
                              height="20px"
                              className="img-fluid ms-2"
                              src={'/assets/icons/cancel_24px.svg'}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {genders.length > 0 && (
                    <>
                      <Row
                        className={classNames(
                          'p-2 p-md-3 p-lg-4',
                          styles.wrapper,
                        )}
                      >
                        <Col sm={12} md={3}>
                          <p>
                            <span className={'fw-bold'}>
                              Identificativo campione
                            </span>{' '}
                            <br />
                            (dovrà essere riportato in modo identico sulla
                            bustina contenente il campione)
                          </p>
                        </Col>
                      </Row>
                      <Row
                        className={classNames(
                          'p-2 p-md-3 p-lg-4',
                          styles.wrapper,
                        )}
                      >
                        <Col sm={12} md={8}>
                          <Table hover size="sm" responsive={true}>
                            <thead>
                              <tr>
                                <th />
                                <th />
                                <th>Specie</th>
                                <th>Sesso</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>{formatPatientTableRows(genders)}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
                <Col sm={{ span: 10, offset: 1 }}>
                  <Form.Control
                    placeholder="Note per il nostro team"
                    type="text"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.notes && !!errors.notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.notes}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
                <Col sm={6}>
                  <Button type="button" onClick={handlePrevStep}>
                    <Image
                      width="10px"
                      height="10px"
                      className="img-fluid me-2"
                      src={'/assets/icons/prev_arrow.svg'}
                    />
                    RITORNA ALLA CREAZIONE ORDINE
                  </Button>
                </Col>
                <Col
                  sm={6}
                  className={
                    'd-flex d-sm-flex justify-content-md-end justify-content-sm-center'
                  }
                >
                  <Button
                    type="button"
                    onClick={() => handleClickNextStep(values.notes)}
                  >
                    INVIA ORDINE
                    <Image
                      width="10px"
                      height="10px"
                      className="img-fluid ms-2"
                      src={'/assets/icons/next_arrow.svg'}
                    />
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default Summary;
