import React from 'react';
import { Row } from 'react-bootstrap';
import styles from './index.module.scss';
import SectionSubTitle from '../SectionSubtitle';
import { default as CS } from 'classnames/bind';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function SectionOrderPayment({ amount, paymentMethod }) {
  const { t } = useTranslation();

  return (
    <>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionSubTitle>{t('order_payment.title', '')}</SectionSubTitle>
      </Row>
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.greenBackground)}>
        <p>
          <b>
            {t('order_payment.total', '')} € {amount}
          </b>
        </p>
        {paymentMethod === 'CREDITO' && (
          <p>{t('order_payment.title_credit', '')}</p>
        )}
        {paymentMethod === 'PPAL' && (
          <p>
            {t('order_payment.paypal.p_1', '')}
            <br />
            {t('order_payment.paypal.p_2.text', '')}{' '}
            <span className={'fw-bold'}>
              {t('order_payment.paypal.p_2.bold', '')}
            </span>
            <br />
            {t('order_payment.paypal.p_3.text', '')}{' '}
            <span className={'fw-bold'}>
              {t('order_payment.paypal.p_3.bold', '')}
            </span>{' '}
            {t('order_payment.paypal.p_3.footer', '')}
          </p>
        )}
        {paymentMethod === 'BONB' && (
          <p>
            {t('order_payment.transfer.p_1.text', '')}{' '}
            <span className={'fw-bold'}>
              {t('order_payment.transfer.p_1.bold', '')}
            </span>{' '}
            <br />
            {t('order_payment.transfer.p_2.text', '')}
            <br />
            <span className={'fw-bold'}>
              {t('order_payment.transfer.p_2.bold', '')}
            </span>
            <br />
            {t('order_payment.transfer.p_3', '')}
          </p>
        )}
        {paymentMethod === 'BOLP' && (
          <p>
            {t('order_payment.postal.p_1.text', '')}
            <span className={'fw-bold'}>
              {t('order_payment.postal.p_1.bold', '')}
            </span>
            <br />
            <span className={'fw-bold'}>{t('order_payment.p_2', '')}</span>
          </p>
        )}
        {paymentMethod === 'CARTA' && <p>{t('order_payment.credit', '')}</p>}
      </Row>
    </>
  );
}

export default SectionOrderPayment;
