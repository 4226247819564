import React, { useEffect } from 'react';
import Logo from 'app/main/components/Logo';
import { Image, Row } from 'react-bootstrap';
import styles from './Order.module.scss';
import { default as CS } from 'classnames/bind';
import SectionTitle from 'app/main/components/SectionTitle';
import { Stepper, Step } from 'react-form-stepper';
import Cart from './Cart/Cart';
import Summary from './Summary/Summary';
import Payment from './Payment/Payment';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addWaitingToAuthorizationStep } from './store/orderSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

const Order = () => {
  const {
    steps,
    stepsRoutes,
    cart: { items },
  } = useSelector(({ order }) => order.order);
  let { url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const hasNoRegisterSpecie = items.some((items) => items?.hasOtherSpecie);
  const paths = pathname.split('/').filter((entry) => entry !== '');
  const lastPath = paths[paths.length - 1];
  const currentStep = stepsRoutes.findIndex((route) => route === lastPath);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (hasNoRegisterSpecie && steps.some((step) => step.label !== 'Attesa')) {
      dispatch(addWaitingToAuthorizationStep());
    }
  }, [dispatch, hasNoRegisterSpecie, steps]);

  useEffect(() => {
    if (items.length === 0 && currentStep !== 0) {
      history.push(`${url}/${stepsRoutes[0]}`);
      toast.warning('Devi prima aggiungere articoli al tuo carrello');
    }
  }, [history, items, stepsRoutes, url, currentStep]);

  const handleStepClick = (step) => {
    if (step !== currentStep) {
      history.push(`${url}/${stepsRoutes[step]}`);
    }
  };

  const handleNextStep = () => {
    history.push(`${url}/${stepsRoutes[currentStep + 1]}`);
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      history.push(`${url}/${stepsRoutes[currentStep - 1]}`);
    }
  };

  return (
    <div>
      <Logo />
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionTitle>{t('payment_order.title', '')}</SectionTitle>
        <Stepper
          activeStep={currentStep}
          stepClassName={classNames(styles.step)}
        >
          {steps.map((step, index) => {
            return (
              <Step
                label={step.label}
                key={index}
                onClick={() => handleStepClick(index)}
                styleConfig={{
                  activeBgColor: step.activeBgColor,
                  inactiveBgColor: step.inactiveBgColor,
                  completedBgColor: step.completedBgColor,
                  borderRadius: '8%',
                  size: '4em',
                }}
              >
                {step?.label === 'Attesa' ? (
                  <i
                    className="fa fa-spinner fa-spin text-primary"
                    aria-hidden="true"
                  />
                ) : (
                  <Image
                    src={
                      index === currentStep
                        ? step.activeIconPath
                        : step.inactiveIconPath
                    }
                  />
                )}
              </Step>
            );
          })}
        </Stepper>
      </Row>
      <Switch>
        <Route path={`${url}`} exact>
          <Redirect to={`${url}/cart`} />
        </Route>
        <Route path={`${url}/cart`}>
          <Cart handleNextStep={handleNextStep} />
        </Route>
        <Route path={`${url}/summary`}>
          <Summary
            handlePrevStep={handlePrevStep}
            handleNextStep={handleNextStep}
          />
        </Route>
        <Route path={`${url}/payment`}>
          <Payment handlePrevStep={handlePrevStep} />
        </Route>
        <Route path={`${url}/*`}>
          <Redirect to={`${url}/cart`} />
        </Route>
      </Switch>
    </div>
  );
};

export default Order;
