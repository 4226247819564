import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SectionTitle from '../SectionTitle';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SectionNotOrdersFound() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>{t('not_orders_found.title', '')}</SectionTitle>
      <Row>
        <Col sm={12} md={6}>
          <Button onClick={() => history.push('/personal/orders-history')}>
            {t('not_orders_found.btn', '')}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default SectionNotOrdersFound;
