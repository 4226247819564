import React, { useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function SelectAutoComplete({
  id,
  fetchData,
  perPage = 5,
  onChange = () => {},
  placeholder = {},
  refSelect,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const _cache = useRef({});

  const { t } = useTranslation();

  const _handlePagination = async (e, shownResults) => {
    const cachedQuery = _cache.current[query];
    // // // Don't make another request if:
    // // // - the cached results exceed the shown results
    // // // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total
    ) {
      return;
    }

    setIsLoading(true);
    const page = cachedQuery.page + 1;
    const data = await fetchData(query, page, perPage);
    const optionsCache = cachedQuery.options.concat(data.options);
    setOptions(optionsCache);
    _cache.current[query] = { ...cachedQuery, options: optionsCache, page };
    setIsLoading(false);
  };

  const _handleSearch = async (query) => {
    setIsLoading(true);
    setQuery(query);
    const page = 1;
    const data = await fetchData(query, page, perPage);
    _cache.current[query] = { ...data, page: 1 };
    setOptions(data.options);
    setIsLoading(false);
  };

  const filterBy = () => true;

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id={id} //"basic-example"
        isLoading={isLoading}
        labelKey="name"
        ref={refSelect}
        minLength={1}
        onPaginate={_handlePagination}
        onSearch={_handleSearch}
        options={options}
        placeholder={placeholder}
        paginate
        maxResults={perPage - 1}
        useCache={false}
        emptyLabel={'Non ci sono riscontri.'}
        searchText={'Ricerca...'}
        paginationText={'Più'}
        onChange={onChange}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {t('auto_complete.required', '')}
      </Form.Control.Feedback>
    </>
  );
}
