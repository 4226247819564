import React, { useEffect, useState } from 'react';
// import styles from './Orders.module.scss';
// import { default as CS } from 'classnames/bind';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { getOrderById } from '@fem/cruds/orderCrud';
import qs from 'qs';
import { toast } from 'react-toastify';
import OrdersHeader from './OrdersHeader/OrdersHeader';
import OrdersBody from './OrdersBody/OrdersBody';
import Order from './Order/Order';

const stepsRoutes = ['summary', 'payment'];
const Orders = () => {
  let { id: orderId } = useParams();
  const { pathname } = useLocation();
  const [order, setOrder] = useState({});
  const [samples, setSamples] = useState([]);
  const history = useHistory();
  const paths = pathname.split('/').filter((entry) => entry !== '');
  const lastPath = paths[paths.length - 1];
  const currentStep = stepsRoutes.findIndex((route) => route === lastPath);

  useEffect(() => {
    const fetchOrderById = async (_orderId) => {
      const getOrdersToast = toast.loading("Ottenere l'ordine...");
      try {
        const query = qs.stringify(
          {
            populate: [
              'client',
              'samples.analyses',
              'samples.analyses.sample.lab',
              'samples.subspecie.specie',
            ],
          },
          {
            encodeValuesOnly: true,
          },
        );
        const {
          data: {
            data: { id, attributes },
          },
        } = await getOrderById(_orderId, query);

        setOrder({
          ...attributes,
          id,
        });
        if (attributes?.samples?.data?.length > 0) {
          setSamples([
            ...attributes.samples.data.map((sample) => {
              return {
                id: sample.id,
                ...sample.attributes,
                analyses: [
                  ...sample.attributes.analyses.data.map((analyse) => ({
                    id: analyse.id,
                    ...analyse.attributes,
                  })),
                ],
              };
            }),
          ]);
        }
        toast.success('Ordine ottenuto con successo 👌');
      } catch (e) {
        console.error(e);
        toast.error("Errore nell'ottenere l'ordine");
      } finally {
        toast.dismiss(getOrdersToast);
      }
    };

    fetchOrderById(orderId);
  }, [orderId, pathname]);

  useEffect(() => {
    if (order?.status === 'RNS_CLIENTE' && lastPath !== 'payment') {
      history.push(`/orders/${orderId}/summary`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  if (['summary', 'payment'].includes(lastPath)) {
    return (
      <Order
        currentStep={currentStep}
        order={order}
        orderId={orderId}
        samples={samples}
      />
    );
  }

  return (
    <>
      <OrdersHeader orderId={orderId} orderStatus={order?.status} />
      <OrdersBody order={order} orderId={orderId} samples={samples} />
    </>
  );
};

export default Orders;
