import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function BackButton({ to }) {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(to)} size="sm">
      <i className="fa fa-arrow-left" aria-hidden="true" />
    </Button>
  );
}

export default BackButton;
