import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { postOrdersPaypalPayment } from '@fem/cruds/orderCrud';

const PaypalOrders = (props) => {
  let location = useLocation();
  const history = useHistory();

  const countOrderTokens = (info) => {
    postOrdersPaypalPayment({ token: info.token })
      .then((response) => {
        const data = response.data;
        if (data.response === 'ok') {
          history.push(`/orders/${data.orderId}`);
        } else {
          history.push('/personal/orders-history');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    countOrderTokens(parsed);
    // eslint-disable-next-line
  }, []);

  return '';
};

export default PaypalOrders;
