import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Button, Image, Modal, Form } from 'react-bootstrap';
import { default as CS } from 'classnames/bind';
import styles from './index.module.scss';
import { removeSample } from 'app/main/pages/Order/store/orderSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

const SectionCart = ({
  summary,
  isHideDeleteButton,
  isBgWhiteFullTransparent,
  isBgTransparent,
  onUpdateIdentifier,
}) => {
  const getClassName = () => {
    if (isBgTransparent) return 'wrapper';
    if (isBgWhiteFullTransparent) return 'wrapper-transparent  ';
    return 'summary';
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [sampleId, setSampleId] = useState(null);
  const [newIdentifier, setNewIdentifier] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSampleId(null);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (sampleId) {
      handleShow();
    }
  }, [sampleId]);

  const renderCertificates = ({ digital, print }) => {
    const certificate = [];
    if (digital.value) {
      certificate.push(
        <div className="d-inline me-2">
          {t('cart.digital', '')}
          <Image
            className={'img-fluid mx-1'}
            src={'/assets/icons/save_24px.svg'}
            title="test"
          />
        </div>,
      );
    }
    if (print.value) {
      certificate.push(
        <div className="d-inline">
          {t('cart.paper', '')}
          <Image
            className={'img-fluid mx-1'}
            src={'/assets/icons/receipt_24px.svg'}
            title="test"
          />
        </div>,
      );
    }
    return certificate;
  };

  const getPrice = ({ price, print }) => {
    if (print.value) {
      return price + 2;
    }
    return price;
  };

  const formatSummaryTableRows = (summary, isHideDeleteButton) => {
    const rows = [];
    if (summary.items.length > 0) {
      summary.items.forEach((study, index) => {
        rows.push(
          <tr
            key={study.id}
            className={classNames(styles.noBorder, styles.rowTable)}
          >
            <td
              rowSpan={study.analyses.length}
              className={classNames(styles.verticalAlignment)}
            >
              <span className={'fw-bold'}>{index + 1}</span>
            </td>

            <td rowSpan={study.analyses.length}>
              <p
                role="button"
                className="pointer-event"
                onClick={() => setSampleId(study.id)}
              >
                <span className={'fw-bold'}>{study.patientInfo.name} </span>{' '}
                <br />
                {study.patientInfo.description} <br />
                {study.patientInfo.mutation} <br />
                {study.patientInfo.birthDate}
              </p>
            </td>

            <td
              rowSpan={study.analyses.length}
              className={classNames(styles.noBorder)}
            >
              {study.specie}
            </td>

            <td>{study.analyses[0].description}</td>
            <td>{renderCertificates(study.analyses[0])}</td>
            <td>€{getPrice(study.analyses[0]).toFixed(2)}</td>
            <td
              rowSpan={study.analyses.length}
              className={classNames(styles.verticalAlignment)}
            >
              {isHideDeleteButton ? (
                ''
              ) : (
                <Button
                  variant="primary"
                  className={classNames(styles.deleteButton)}
                  onClick={() => dispatch(removeSample(study.id))}
                >
                  <Row>
                    <Col sm={12}>
                      <Image
                        className={'img-fluid'}
                        src={'/assets/icons/close_24px.svg'}
                        onMouseLeave={(e) =>
                          (e.currentTarget.src = '/assets/icons/close_24px.svg')
                        }
                        onMouseOver={(e) =>
                          (e.currentTarget.src =
                            '/assets/icons/close_white_24px.svg')
                        }
                      />
                    </Col>
                    <Col sm={12}>
                      <span>{t('cart.delete', '')}</span>
                    </Col>
                  </Row>
                </Button>
              )}
            </td>
          </tr>,
        );

        if (study.analyses.length > 1) {
          study.analyses.forEach((analysis, index) => {
            if (index > 0) {
              rows.push(
                <tr
                  key={analysis.id}
                  className={
                    index === study.analyses.length - 1
                      ? styles.rowTable
                      : classNames(styles.noBorder, styles.rowTable)
                  }
                >
                  <td>{analysis.description}</td>
                  <td>{renderCertificates(analysis)}</td>
                  <td>€{analysis.price.toFixed(2)}</td>
                </tr>,
              );
            }
          });
        }
      });
    } else {
      rows.push(
        <tr className={styles.rowTable}>
          <td className="text-center" colSpan={15}>
            <i>{t('cart.empty', '')}</i>
          </td>
        </tr>,
      );
    }

    rows.push(
      <tr key={'subtotal'}>
        <td />
        <td />
        <td />
        <td />
        <td>{t('cart.total', '')}</td>
        <td>€{summary.subTotal.toFixed(2)}</td>
      </tr>,
    );
    if (!!summary.smapDiscount.total) {
      rows.push(
        <tr key={'discount1'} className={classNames(styles.noBorder)}>
          <td />
          <td />
          <td />
          <td />
          <td>{t('cart.discount_smap', '')}</td>
          <td>- €{summary.smapDiscount.total.toFixed(2)}</td>
        </tr>,
      );
    }
    rows.push(
      <tr key={'discount2'} className={classNames(styles.noBorder)}>
        <td />
        <td />
        <td />
        <td />
        <td>{t('cart.discount_list', '')}</td>
        <td>
          - €{(+summary.discount - summary.smapDiscount.total).toFixed(2)}
        </td>
      </tr>,
    );

    /*    rows.push(
      <tr key={'discount'} className={classNames(styles.noBorder)}>
        <td />
        <td />
        <td />
        <td />
        <td>SCONTO TOTALE</td>
        <td>- €{summary.discount.toFixed(2)}</td>
      </tr>,
    ); */
    rows.push(
      <tr key={'total'} className={classNames(styles.noBorder)}>
        <td />
        <td />
        <td />
        <td />
        <td>
          <p>
            <span className={'fw-bold'}>{t('cart.total_service', '')}</span>{' '}
            {t('cart.iva', '')}(iva inclusa)
          </p>
        </td>
        <td>
          <p className={'fw-bold'}>€{summary.total.toFixed(2)}</p>
        </td>
      </tr>,
    );

    return rows;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={styles.wrapper}>
          <Modal.Title>{t('cart.change_identifier', '')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.wrapper}>
          <Form>
            <Form.Group className="mb-3" controlId="identificativo" as={Row}>
              <Form.Label>{t('cart.identifier', '')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('cart.identifier', '')}
                onChange={(e) => {
                  setNewIdentifier(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.wrapper}>
          <Button variant="secondary" onClick={handleClose}>
            {t('cart.close', '')}
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              onUpdateIdentifier({ id: sampleId, identifier: newIdentifier })
            }
          >
            {t('cart.save_changes', '')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className={classNames('p-2 p-md-3 p-lg-4', getClassName())}>
        <p className="fw-bold">{t('cart.sample_id', '')}</p>
        <p>{t('cart.message', '')}</p>
        <Row className="mt-3">
          <Col sm={12} lg={12}>
            <Table size="sm" responsive={true}>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th>{t('cart.species', '')}</th>
                  <th>{t('cart.analysis', '')}</th>
                  <th>{t('cart.certificates', '')}</th>
                  <th>{t('cart.price', '')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {formatSummaryTableRows(summary, isHideDeleteButton)}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default SectionCart;
