import Personal from './Personal';
import authRoles from 'app/auth/authRoles';

const PersonalConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: false,
          personalMenu: true,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/personal',
      component: Personal,
    },
    {
      path: '/personal/orders-history',
      component: Personal,
    },
    {
      path: '/personal/edit-data',
      component: Personal,
    },
    {
      path: '/personal/package-credits',
      component: Personal,
    },
  ],
};

export default PersonalConfig;
