import * as yup from 'yup';

export const schema = yup.object().shape({
  identifier: yup.string().required('Il campo è obbligatorio.'),
  otherSpecie: yup.string().when('notFoundSpecie', {
    is: true,
    then: yup.string().max(255).required('Il campo è obbligatorio.'),
  }),
  specie: yup.object().when('notFoundSpecie', {
    is: false,
    then: yup.object().shape({
      id: yup.number().min(1).required('Il campo è obbligatorio.'),
      specie: yup.string().required('Il campo è obbligatorio.'),
      subSpecie: yup.string(),
      common: yup.string(),
    }),
  }),
  samplesAdded: yup.array().min(1),
  notFoundSpecie: yup.bool(),
  mutation: yup.string(),
  birthDate: yup.date(),
  owner: yup.string(),

  smap: yup.bool(),
  smapPrint: yup.bool(),
  smapDigital: yup.bool(),

  smau: yup.bool(),
  smauPrint: yup.bool(),
  smauDigital: yup.bool(),

  smar: yup.bool(),
  smarPrint: yup.bool(),
  smarDigital: yup.bool(),

  apv: yup.bool(),
  apvPrint: yup.bool(),
  apvDigital: yup.bool(),

  bfdv: yup.bool(),
  bfdvPrint: yup.bool(),
  bfdvDigital: yup.bool(),

  clamidia: yup.bool(),
  clamidiaPrint: yup.bool(),
  clamidiaDigital: yup.bool(),
});

export const TOOLTIP_PRINT_TEXT = 'Attestato cartaceo';
export const TOOLTIP_DIGITAL_TEXT =
  'Attestato digitale in formato pdf, scaricabile e stampabile.';

export const defaultSpecie = {
  id: 0,
  specie: '',
  subSpecie: '',
  common: '',
};
export const initialValues = {
  identifier: '',
  otherSpecie: '',
  specie: defaultSpecie,
  samplesAdded: [],
  notFoundSpecie: false,
  mutation: '',
  birthDate: '',
  owner: '',

  smap: false,
  smapPrint: false,
  smapDigital: false,

  smau: false,
  smauPrint: false,
  smauDigital: false,

  smar: false,
  smarPrint: false,
  smarDigital: false,

  apv: false,
  apvPrint: false,
  apvDigital: false,

  bfdv: false,
  bfdvPrint: false,
  bfdvDigital: false,

  clamidia: false,
  clamidiaPrint: false,
  clamidiaDigital: false,
};
export const initialOriginalPrices = {
  smap: 0,
  smau: 0,
  smar: 0,
  apv: 0,
  bfdv: 0,
  clamidia: 0,
  digital: 0,
  print: 0,
};
