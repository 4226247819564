import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './app/App';
import { ErrorBoundary } from 'react-error-boundary';
import Error500Page from './app/main/pages/errors/500/Error500Page';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['it', 'en'],
    fallbackLng: 'it',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/translations/{{lng}}/global.json',
    },
    react: { useSuspense: false },
  });

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>...</div>}>
      <ErrorBoundary
        FallbackComponent={Error500Page}
        onError={(error, errorInfo) => console.error({ error, errorInfo })}
      >
        <App />
      </ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
