import React, { useEffect, useState } from 'react';
import styles from './EditData.module.scss';
import { default as CS } from 'classnames/bind';
import SectionTitle from '../../../components/SectionTitle';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SectionSubTitle from '../../../components/SectionSubtitle';
import { Formik } from 'formik';
import * as yup from 'yup';
import useToggle from '../../../../../@fem/hooks/useToggle';
import {
  deleteClientAssociationById,
  getClientById,
  postNewAssociationMemberships,
  updateClientAssociationById,
  updateClientById,
} from '@fem/cruds/userCrud';
import { useSelector } from 'react-redux';
import { getAssociations } from '../../../../../@fem/cruds/associationCrud';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);
const schema = yup.object().shape({
  firsNameLastNameOrCompany: yup.string().required('Il campo è obbligatorio.'),
  taxIdentificationNumberOrVatNumber: yup
    .string()
    .required('Il campo è obbligatorio.'),
  address: yup.string().required('Il campo è obbligatorio.'),
  houseNumber: yup.string().required('Il campo è obbligatorio.'),
  zipCode: yup.number().required('Il campo è obbligatorio.'),
  city: yup.string().required('Il campo è obbligatorio.'),
  province: yup.string().required('Il campo è obbligatorio.'),
  state: yup.string().required('Il campo è obbligatorio.'),
  phoneNumber: yup.string().required('Il campo è obbligatorio.'),
  secondaryPhoneNumber: yup.string(),
  email: yup
    .string()
    .email('Deve essere un emai valido')
    .max(255)
    .required('Il campo è obbligatorio.'),
  confirmEmail: yup
    .string()
    .email('Deve essere un emai valido')
    .oneOf([yup.ref('email'), null], 'Le e-mail devono corrispondere')
    .max(255)
    .required('Il campo è obbligatorio.'),
  pec: yup.string().email('Deve essere un emai valido'),
  sdiRecipientCode: yup.string(),
  companyTaxCode: yup.string(),
  password: yup.string().max(255),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Le password devono essere le stesse')
    .max(255),
  showConvention: yup.string().required('Il campo è obbligatorio.'),
  associationId: yup.string().when('showConvention', {
    is: 'show',
    then: yup.string().max(255).required('Il campo è obbligatorio.'),
  }),
  cardNumber: yup.string().when('showConvention', {
    is: 'show',
    then: yup.string().max(255).required('Il campo è obbligatorio.'),
  }),
});
const _data = {
  languageUser: '',
  firsNameLastNameOrCompany: '',
  taxIdentificationNumberOrVatNumber: '',
  address: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  province: '',
  state: '',
  phoneNumber: '',
  secondaryPhoneNumber: '',
  email: '',
  confirmEmail: '',
  pec: '',
  sdiRecipientCode: '',
  companyTaxCode: '',
  password: '',
  confirmPassword: '',
  associationId: '',
  cardNumber: '',
  showConvention: 'hide',
};
const EditData = () => {
  const [isVisibleConvention, setIsVisibleConvention] = useToggle();
  const [data, setData] = useState(_data);
  const [associationData, setAssociationData] = useState([]);
  const wrapperStyles = classNames('p-2 p-md-3 p-lg-4', styles.wrapper);
  const personalDataStyles = classNames(
    'pb-2 pb-md-5 px-1 px-md-3 shadow',
    styles.bgTransparent,
  );
  const formStyles = classNames('p-2 p-md-3 p-lg-4', styles.bgTransparent);
  const formGroupStyles = classNames('mb-3 d-flex align-items-center');
  const conventionStyles = classNames('mb-3 d-flex align-items-center', {
    'd-none': !isVisibleConvention,
  });

  const {
    client: { id: clientId },
  } = useSelector(({ auth }) => {
    return auth.user.data;
  });

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showConvention, setShowConvention] = useState('hide');
  const [associationMembership, setAssociationMembership] = useState({});

  useEffect(() => {
    getAssociations()
      .then(function (response) {
        const {
          data: { data },
        } = response;
        setAssociationData(data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { data: user },
        } = await getClientById(clientId);
        const {
          attributes: {
            tax_id,
            company_name,
            city,
            address,
            address_number,
            province,
            zip_code,
            phone,
            secondary_phone,
            email,
            language,
            country,
            corporate_tax_code,
            pec,
            recipient_code,
            association_membership,
          },
        } = user;

        setShowConvention(association_membership.data ? 'show' : 'hide');
        setAssociationMembership(association_membership.data);

        setData({
          ...data,
          languageUser: language,
          firsNameLastNameOrCompany: company_name,
          taxIdentificationNumberOrVatNumber: tax_id,
          address: address,
          houseNumber: address_number,
          zipCode: zip_code,
          city: city,
          province: province,
          state: country,
          phoneNumber: phone,
          secondaryPhoneNumber: secondary_phone,
          email: email,
          confirmEmail: email,
          companyTaxCode: corporate_tax_code,
          pec,
          sdiRecipientCode: recipient_code,
          showConvention: association_membership.data ? 'show' : 'hide',
          associationId: association_membership.data
            ? association_membership.data.attributes.association.data.id
            : '',
          cardNumber: association_membership.data
            ? association_membership.data.attributes.card
            : '',
        });

        if (association_membership.data) {
          // values.showConvention = 'show';
          setIsVisibleConvention(true);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [updateClientById, clientId, shouldUpdate]);

  const { t } = useTranslation();

  return (
    <Row className={wrapperStyles}>
      <SectionTitle>{t('personal.title', '')}</SectionTitle>
      <p>{t('personal.subtitle', '')}</p>
      <Row className="mb-3 mx-1 d-flex align-items-end">
        <Col sm={9} className={personalDataStyles}>
          <SectionSubTitle icon="/assets/icons/assignment_ind_24px_outlined.svg">
            {t('personal.card.title', '')}
          </SectionSubTitle>
          <div className={styles.personalDataWrapper}>
            <p>
              {t('personal.card.company_name', '')}{' '}
              <b>{data.firsNameLastNameOrCompany}</b>
            </p>
            <p>
              {t('personal.card.tax_id', '')}{' '}
              <b>{data.taxIdentificationNumberOrVatNumber}</b>
            </p>
            <p>
              {t('personal.card.address', '')}{' '}
              <b>
                {data.address},{data.houseNumber},{data.city}-{data.province}{' '}
                {data.zipCode}
              </b>
            </p>
            <p>
              {t('personal.card.phone', '')} <b>{data.phoneNumber}</b>
            </p>
            <p>
              {t('personal.card.email', '')} <b>{data.email}</b>
            </p>
            <p>
              {t('personal.card.pec', '')} <b>{data.pec}</b>
            </p>
            <p>
              {t('personal.card.sdi', '')} <b>{data.sdiRecipientCode}</b>
            </p>
            <p>
              {t('personal.card.tax_code', '')} <b>{data.companyTaxCode}</b>
            </p>
          </div>
        </Col>
      </Row>

      <SectionTitle>{t('personal.edit_form.title', '')}</SectionTitle>
      <Formik
        validationSchema={schema}
        enableReinitialize
        onSubmit={async (formData) => {
          const updatedClient = {
            type: formData.typeUser,
            tax_id: formData.taxIdentificationNumberOrVatNumber,
            company_name: formData.firsNameLastNameOrCompany,
            address: formData.address,
            address_number: formData.houseNumber,
            province: formData.province,
            zip_code: formData.zipCode.toString(),
            city: formData.city,
            phone: formData.phoneNumber,
            secondary_phone: formData.secondaryPhoneNumber,
            email: formData.email,
            language: formData.languageUser,
            country: formData.state,
            corporate_tax_code: formData.companyTaxCode,
            pec: formData.pec,
            recipient_code: formData.sdiRecipientCode,
          };
          if (formData.showConvention === 'show') {
            const _associationId = Number(formData.associationId);
            if (associationMembership) {
              await updateClientAssociationById(associationMembership.id, {
                data: {
                  card: formData.cardNumber,
                  association: _associationId,
                },
              });
            } else {
              const membershipsData = {
                data: {
                  name: formData.firsNameLastNameOrCompany,
                  card: formData.cardNumber,
                  association: _associationId,
                  unofficial: true,
                  client: clientId,
                },
              };
              await postNewAssociationMemberships(membershipsData);
            }
          } else {
            associationMembership &&
              (await deleteClientAssociationById(associationMembership.id));
          }
          toast
            .promise(
              updateClientById(clientId, {
                data: updatedClient,
              }),
              {
                pending: 'Aggiornamento dei dati...',
                success: 'Dati aggiornati  👌',
                error: {
                  render({ data }) {
                    const {
                      response: {
                        data: { error },
                      },
                    } = data;
                    switch (error.message) {
                      case 'Invalid identifier or password':
                        return 'Utente o password non validi';
                      default:
                        return "Errore nell'aggiornamento dei dati";
                    }
                  },
                },
              },
            )
            .then(({ result }) => {
              setShouldUpdate(!shouldUpdate);
            });
        }}
        initialValues={data}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className={formStyles}>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.language', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Select
                  aria-label="Idioma"
                  value={values.languageUser}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.languageUser && !!errors.languageUser}
                  name="languageUser"
                >
                  <option value="it">Italian</option>
                  <option value="en">English</option>
                  <option value="de">German</option>
                  <option value="fr">French</option>
                  <option value="es">Spanish</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.languageUser}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <SectionSubTitle icon="/assets/icons/assignment_ind_24px_outlined.svg">
              {t('personal.edit_form.personal.title', '')}
            </SectionSubTitle>

            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={6} lg={2} className="mb-md-3">
                {t('personal.edit_form.personal.name', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={6} lg={4} className="mb-md-3 ">
                <Form.Control
                  placeholder="Nome e Cognome / Ragione Sociale"
                  type="text"
                  name="firsNameLastNameOrCompany"
                  value={values.firsNameLastNameOrCompany}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.firsNameLastNameOrCompany &&
                    !!errors.firsNameLastNameOrCompany
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firsNameLastNameOrCompany}
                </Form.Control.Feedback>
              </Col>

              <Form.Label column sm={6} lg={2}>
                {t('personal.edit_form.personal.tax_id', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={6} lg={4}>
                <Form.Control
                  placeholder="Codice Fiscale / Partita Iva"
                  type="text"
                  name="taxIdentificationNumberOrVatNumber"
                  value={values.taxIdentificationNumberOrVatNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.taxIdentificationNumberOrVatNumber &&
                    !!errors.taxIdentificationNumberOrVatNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.taxIdentificationNumberOrVatNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2} lg={2} className="mb-md-3">
                {t('personal.edit_form.personal.address', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4} lg={4}>
                <Form.Control
                  placeholder="Indirizzo"
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.address && !!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Col>

              <Form.Label column sm={3} lg={1}>
                {t('personal.edit_form.personal.address_number', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={3} lg={2}>
                <Form.Control
                  placeholder="n°"
                  type="text"
                  name="houseNumber"
                  value={values.houseNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.houseNumber && !!errors.houseNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.houseNumber}
                </Form.Control.Feedback>
              </Col>

              <Form.Label column sm={2} lg={1}>
                {t('personal.edit_form.personal.cap', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4} lg={2}>
                <Form.Control
                  placeholder="CAP"
                  type="number"
                  name="zipCode"
                  value={values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.zipCode && !!errors.zipCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zipCode}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2} lg={2} className="mb-md-3">
                {t('personal.edit_form.personal.city', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4} lg={4}>
                <Form.Control
                  placeholder="Città"
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.city && !!errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Col>

              <Form.Label column sm={3} lg={1}>
                {t('personal.edit_form.personal.province', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={3} lg={2}>
                <Form.Control
                  placeholder="Provincia"
                  type="text"
                  name="province"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.province && !!errors.province}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.province}
                </Form.Control.Feedback>
              </Col>

              <Form.Label column sm={2} lg={1}>
                {t('personal.edit_form.personal.country', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4} lg={2}>
                <Form.Select
                  aria-label="País"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.state && !!errors.state}
                  name="state"
                >
                  <option value="-1">País</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AG">Antigua &amp; Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahama</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="VG">British Virgin Islands</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CK">Cook Iislands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="ZR">Democratic Republic of the Congo</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="TP">East Timor</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard &amp; McDonald Islands</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IR">Islamic Republic of Iran</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="CI">Ivory Coast</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="LY">Lybia</option>
                  <option value="MO">Macau</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="MS">Monserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome &amp; Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SH">St. Helena</option>
                  <option value="KN">St. Kitts and Nevis</option>
                  <option value="PM">St. Pierre &amp; Miquelon</option>
                  <option value="VC">St. Vincent &amp; the Grenadines</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard &amp; Jan Mayen Islands</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad &amp; Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks &amp; Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom (Great Britain)</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="VI">United States Virgin Islands</option>
                  <option value="US">United States of America</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VA">Vatican City State (Holy See)</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="WF">Wallis &amp; Futuna Islands</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <SectionSubTitle icon="/assets/icons/contact_phone_24px_outlined.svg">
              {t('personal.edit_form.contact.title', '')}
            </SectionSubTitle>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.contact.phone', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Telefono Nr"
                  type="text"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Col>
              <Form.Label column sm={2}>
                {t('personal.edit_form.contact.phone_secondary', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Telefono Secondario"
                  type="text"
                  name="secondaryPhoneNumber"
                  value={values.secondaryPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.secondaryPhoneNumber &&
                    !!errors.secondaryPhoneNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.secondaryPhoneNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.contact.email', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Col>
              <Form.Label column sm={2}>
                {t('personal.edit_form.contact.repeat_email', '')}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Ripeti email"
                  type="text"
                  name="confirmEmail"
                  value={values.confirmEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.confirmEmail && !!errors.confirmEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmEmail}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <SectionSubTitle icon="/assets/icons/domain_24px_outlined.svg">
              {t('personal.edit_form.vat_holders.title', '')}
            </SectionSubTitle>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.vat_holders.pec', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="PEC"
                  type="text"
                  name="pec"
                  value={values.pec}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.pec && !!errors.pec}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.pec}
                </Form.Control.Feedback>
              </Col>
              <Form.Label column sm={2}>
                {t('personal.edit_form.vat_holders.sdi', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Codice destinatario SDI"
                  type="text"
                  name="sdiRecipientCode"
                  value={values.sdiRecipientCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.sdiRecipientCode && !!errors.sdiRecipientCode
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sdiRecipientCode}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.vat_holders.tax_code', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Codice Fiscale Aziendale"
                  type="text"
                  name="companyTaxCode"
                  value={values.companyTaxCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.companyTaxCode && !!errors.companyTaxCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.companyTaxCode}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <SectionSubTitle icon="/assets/icons/https_24px_outlined.svg">
              {t('personal.edit_form.password.title', '')}
            </SectionSubTitle>
            <Form.Group as={Row} className={formGroupStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.password.password', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Col>
              <Form.Label column sm={2}>
                {t('personal.edit_form.password.repeat_password', '')}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Ripeti Password"
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <SectionSubTitle icon="/assets/icons/supervised_user_circle_24px_outlined.svg">
              {t('personal.edit_form.conventions.title', '')}{' '}
            </SectionSubTitle>
            <Form.Group as={Row} className={formGroupStyles}>
              <Col sm={6}>
                {t('personal.edit_form.conventions.subtitle', '')}
              </Col>
              <Col sm={4}>
                <Form.Check
                  onChange={(e) => {
                    handleChange(e);
                    setShowConvention('hide');
                    e.target.value === 'hide' && setIsVisibleConvention(false);
                  }}
                  checked={showConvention === 'hide'}
                  name="showConvention"
                  type="radio"
                  label={t('personal.edit_form.conventions.no', '')}
                  value="hide"
                />
              </Col>
              <Col sm={2}>
                <Form.Check
                  onChange={(e) => {
                    handleChange(e);
                    setShowConvention('show');
                    e.target.value === 'show' && setIsVisibleConvention(true);
                  }}
                  checked={showConvention === 'show'}
                  name="showConvention"
                  type="radio"
                  value="show"
                  label={t('personal.edit_form.conventions.si', '')}
                />
              </Col>
              <Form.Control.Feedback type="invalid">
                {errors.showConvention}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Row} className={conventionStyles}>
              <Form.Label column sm={2}>
                {t('personal.edit_form.conventions.association', '')}{' '}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Select
                  aria-label="Associazione"
                  value={values.associationId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.associationId && !!errors.associationId}
                  name="associationId"
                >
                  <option value="">Nome della associazione</option>
                  {associationData.map((item, key) => {
                    const {
                      id,
                      attributes: { name },
                    } = item;
                    return (
                      <option value={id} key={key}>
                        {name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.associationId}
                </Form.Control.Feedback>
              </Col>
              <Form.Label column sm={2}>
                {t('personal.edit_form.conventions.card', '')}{' '}
                <span className="text-primary ms-1">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  placeholder="Numero della carta"
                  type="text"
                  name="cardNumber"
                  value={values.cardNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.cardNumber && !!errors.cardNumber}
                  disabled={!isVisibleConvention}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cardNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-5 d-flex justify-content-end">
              <Col md={5} lg={4} xl={3} className="d-grid gap-0">
                <Button size="lg" type="submit">
                  {t('personal.edit_form.save_btn', '')}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

export default EditData;
