import React from 'react';
import styles from './index.module.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { default as CS } from 'classnames/bind';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function SectionOrderPendingValidation() {
  const { t } = useTranslation();

  return (
    <Row
      className={classNames(
        'p-2 p-md-3 p-lg-4d-flex justify-content-center',
        styles.wrapper,
      )}
    >
      <Col sm={12} md={10} lg={8}>
        <Alert variant="danger" className="p-2 mb-0 text-center">
          <i className="fa fa-refresh fa-spin" />
          {t('order_pending_validation.title', '')}
          <i className="fa fa-refresh fa-spin" />
          <br />
          <br />
          {t('order_pending_validation.subtitle', '')}
          <br />
          <br />
        </Alert>
      </Col>
    </Row>
  );
}

export default SectionOrderPendingValidation;
