import React, { useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Logo from 'app/main/components/Logo';
import styles from './Login.module.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(
      'L’email non è corretta. Verificare che prima o dopo l’indirizzo inserito non siano presenti spazi vuoti.',
    )
    .max(255)
    .required('Il campo è obbligatorio.'),
  password: yup.string().max(255).required('Il campo è obbligatorio.'),
});

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { success } = useSelector(({ auth }) => auth.login);

  const { t } = useTranslation();

  // const { t } = useTranslation(['UIStringResourcePerApp']);
  // UIStringResourcePerApp is UIStringResourcePerApp.json

  useEffect(() => {
    if (success) {
      history.push('/personal');
    }
    // eslint-disable-next-line
  }, [success]);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={({ email, password }) => {
        dispatch(submitLogin({ email, password }));
      }}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Logo />
          <Row className={styles.bgTransparent}>
            <Col className="pt-4 text-center">
              <div>
                <h2 className="mb-3">{t('login.welcome', '')}</h2>
                <p>
                  {t('login.subtitle', '')}
                  <Link to="/forgot-password">
                    {t('login.subtitle_link', '')}
                  </Link>
                  {/* </span> */}
                </p>
              </div>
              <Row className="justify-content-md-center">
                <Col lg={5} md={8} sm>
                  <Form.Control
                    placeholder="Email"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                    className={styles.input}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                  <Form.Control
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                    className={styles.input}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                  <Button type="submit" variant="primary" className="my-3">
                    {t('login.login_btn', '')}
                  </Button>

                  <p className="my-2">{t('login.new_client', '')}</p>
                  <Link
                    to="/register"
                    role="button"
                    className="my-3 btn btn-primary "
                  >
                    {t('login.register_btn', '')}
                  </Link>

                  <p className="my-2">{t('login.forgot_password', '')}</p>
                  <Link
                    to="/forgot-password"
                    role="button"
                    className="my-3 btn btn-primary "
                  >
                    {t('login.forgot_password_btn', '')}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4 bg-white">
            <Col className="pt-4">
              <h2>{t('login.notices.title', '')}</h2>
              {t('login.notices.message', '')}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
