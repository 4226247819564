import React from 'react';
import Logo from '../../../components/Logo';
import { Badge, Col, Row } from 'react-bootstrap';
import styles from '../Orders.module.scss';
import BackButton from '../../../components/BackButton';
import SectionTitle from '../../../components/SectionTitle';
import SectionNotOrdersFound from '../../../components/SectionNotOrdersFound';
import { getClassOrNameByStatus } from '../../Personal/OrdersHistory/util';
import { default as CS } from 'classnames/bind';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function OrdersHeader({ orderId, orderStatus }) {
  const { textForBadge, classForBgBadge } = getClassOrNameByStatus(orderStatus);

  const { t } = useTranslation();

  return (
    <>
      <Logo />
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <Col className="mb-4">
          <BackButton to="/personal/orders-history" />
        </Col>

        {orderStatus ? (
          <SectionTitle>
            {t('orders_header.title', '')}
            {orderId} - <Badge bg={classForBgBadge}>{textForBadge}</Badge>
          </SectionTitle>
        ) : (
          <SectionNotOrdersFound />
        )}
      </Row>
    </>
  );
}

export default OrdersHeader;
