import Certificate from './Certificate';
import authRoles from 'app/auth/authRoles';

const CertificateConfig = {
  settings: {
    layout: {
      custom: true,
      config: {
        navbar: {
          generalMenu: true,
          personalMenu: false,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/certificate/:code',
      component: Certificate,
    },
  ],
};

export default CertificateConfig;
