import React from 'react';
import styles from './index.module.scss';
import { Col, Image, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

const SectionSubTitle = ({ children, icon }) => {
  const _classNames = classNames.bind(styles);
  const sectionSubTitleStyles = _classNames(
    'd-flex',
    { 'align-items-center': icon },
    styles.subTitle,
  );
  return (
    <Row className="mb-2 mt-4">
      <Col sm={12} className={sectionSubTitleStyles}>
        {children} {icon && <Image className="img-fluid ms-2" src={icon} />}
      </Col>
    </Row>
  );
};

export default SectionSubTitle;
