import FemLoading from '@fem/core/FemSuspense';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
const FemSuspense = function (props) {
  return (
    <React.Suspense fallback={<FemLoading {...props.loadingProps} />}>
      {props.children}
    </React.Suspense>
  );
};

FemSuspense.propTypes = {
  loadingProps: PropTypes.object,
};

FemSuspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default FemSuspense;
