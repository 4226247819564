import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import { toast } from 'react-toastify';
import { loginReset } from './loginSlice';

export const setUserData = (user) => async (dispatch) => {
  console.log('setUserData');
  dispatch(
    setUser({
      role: ['user'],
      data: user,
    }),
  );
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }
  dispatch(loginReset());
  jwtService.logout();

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  jwtService
    .updateUserData(user)
    .then(() => {
      toast.info('User data saved with api');
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

const initialState = {
  role: [], // guest
  data: {
    client: {
      fem_credit: 0
    }
  }
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
    setNewCredits: (state, action) => {
      state.data = {
        ...state.data,
        client: {
          ...state.data.client,
          fem_credit: action.payload
        }
      };
    },
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut, setNewCredits } = userSlice.actions;

export default userSlice.reducer;
