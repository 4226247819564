import axios from 'axios';
import { BASE_URL } from './constants';
import * as qs from 'qs';

export const getSpecies = (params = {}) => {
  const query = qs.stringify(params, {
    encodeValuesOnly: true,
  });
  return axios.get(`${BASE_URL}/api/subspecies?${query}`);
};

export const getPricingSchemes = (params = {}) => {
  return axios.get(`${BASE_URL}/api/pricing-schemes/me`);
};

export const getMyOrders = (params = {}) => {
  const query = qs.stringify(
    {
      pagination: {
        page: 1,
        pageSize: 500,
      },
      sort: ['updatedAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  return axios.get(`${BASE_URL}/api/orders/me?${query}`);
};

export const getDefaultPricingSchemes = (params = {}) => {
  return axios.get(`${BASE_URL}/api/pricing-schemes/1`);
};

export const getOrderById = (orderId, query) => {
  return axios.get(`${BASE_URL}/api/orders/${orderId}?${query}`);
};

export const createOrder = (data) => {
  return axios.post(`${BASE_URL}/api/orders`, data);
};

export const updateOrder = (order) => {
  return axios.put(`${BASE_URL}/api/orders/${order.id}`, order);
};

export const createSample = (data) => {
  return axios.post(`${BASE_URL}/api/samples`, data);
};

export const createAnalysis = (data) => {
  return axios.post(`${BASE_URL}/api/analyses`, data);
};

export const postOrdersPaypalPayment = (data) => {
  return axios.post(`${BASE_URL}/api/order/payments/count`, data);
};

export const postCreateLinkPaypalByOrderId = (data) => {
  return axios.post(`${BASE_URL}/api/order/paypal`, data);
};

export const postOrdersPaypalPaymentCancel = (data) => {
  return axios.post(`${BASE_URL}/api/paypal-canceleds`, data);
};
