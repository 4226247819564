import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import {
  NavLink,
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import OrdersHistory from './OrdersHistory/OrdersHistory';
import EditData from './EditData/EditData';
import PackageCredits from './PackageCredits/PackageCredits';
import Logo from 'app/main/components/Logo';
import './personal.scss';
import { useTranslation } from 'react-i18next';

const Personal = () => {
  const { pathname } = useLocation();
  let { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (pathname === '/personal') {
      history.push(`${url}/orders-history`);
    }
  }, [pathname, history, url]);

  const { t } = useTranslation();

  return (
    <div className="personal-wrapper">
      <Logo />
      <Nav
        defaultActiveKey={1}
        activeKey={pathname}
        variant="tabs"
        className="justify-content-end"
      >
        <Nav.Item>
          <Nav.Link eventKey={1} as={NavLink} to={`${url}/orders-history`}>
            {t('tabs.personal.orders', '')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2} as={NavLink} to={`${url}/edit-data`}>
            {t('tabs.personal.personal', '')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3} as={NavLink} to={`${url}/package-credits`}>
            {t('tabs.personal.packages', '')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Switch>
        <Route path={`${url}/orders-history`}>
          <OrdersHistory />
        </Route>
        <Route path={`${url}/edit-data`}>
          <EditData />
        </Route>
        <Route path={`${url}/package-credits`}>
          <PackageCredits />
        </Route>
      </Switch>
    </div>
  );
};

export default Personal;
