import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Image,
  Row,
  Table,
  Tooltip,
  OverlayTrigger,
  Alert,
} from 'react-bootstrap';
import { default as CS } from 'classnames/bind';
import { Formik } from 'formik';
import SectionSubTitle from 'app/main/components/SectionSubtitle';
import SectionTitle from 'app/main/components/SectionTitle';
import SectionLabel from 'app/main/components/SectionLabel';
import SelectAutoComplete from 'app/main/components/SelectAutocomplete';
import {
  getSpecies,
  getPricingSchemes,
  getDefaultPricingSchemes,
} from '@fem/cruds/orderCrud';
import 'app/main/components/SelectAutocomplete/selectCustomStyle.css';
import styles from './Cart.module.scss';
import SectionCart from 'app/main/components/SectionCart';
import {
  initialOriginalPrices,
  defaultSpecie,
  initialValues,
  schema,
  TOOLTIP_DIGITAL_TEXT,
  TOOLTIP_PRINT_TEXT,
} from './util';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addElementToCart,
  addInvoiceToCart,
  applySmapDiscount,
  removeSmapDiscount,
  updateIdentifierBySampleId,
} from '../store/orderSlice';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

const Cart = ({ handleNextStep }) => {
  const { t } = useTranslation();
  const { full_name } = useSelector(({ auth }) => auth.user.data);
  const {
    cart,
    cart: { requiredInvoice },
  } = useSelector(({ order }) => order.order);
  // const [originalPrices, setOriginalPrices] = useState(initialOriginalPrices);
  const [prices, setPrices] = useState(initialOriginalPrices);
  const [defaultPrices, setDefaultPrices] = useState();
  const [priceSchema, setPriceSchema] = useState();
  const dispatch = useDispatch();
  const refSelect = useRef();
  const refTitle = useRef(null);
  const refHadDiscount = useRef(false);

  const handleSearchSpecies = async (query, page = 1, pageSize = 5) => {
    const params = {
      populate: 'specie',
      sort: ['specie.name', 'name'],
      filters: {
        $or: [
          {
            name: {
              $containsi: query,
            },
          },
          {
            specie: {
              name: {
                $containsi: query,
              },
            },
          },
        ],
      },
      pagination: {
        page: page,
        pageSize: pageSize,
      },
    };
    const response = await getSpecies(params);
    const result = await response.data;
    const {
      data,
      meta: {
        pagination: { total },
      },
    } = result;
    const options = data.map((subspecie) => {
      const {
        id: idSubSpecies,
        attributes: {
          name,
          common_names,
          specie: {
            data: {
              attributes: { name: nameSpecie },
            },
          },
        },
      } = subspecie;

      return {
        id: idSubSpecies,
        specie: nameSpecie,
        subSpecie: name,
        common: common_names,
        name: `${nameSpecie}${name !== '' ? ` - ${name}` : ''}`,
      };
    });
    return {
      options,
      total: total,
    };
  };
  const handleChangeSpecies = (data, setFieldValue) => {
    // data contains a array of objects with species data
    if (data.length) {
      setFieldValue('specie', data[0]);
    } else {
      setFieldValue('specie', defaultSpecie);
    }
  };

  useEffect(() => {
    const hasDiscount = [3, 47, 53, 60].includes(priceSchema?.id);
    const countOfSmaps = cart.items.flatMap((item) =>
      item.analyses.filter((a) => a.key === 'smap'),
    ).length;

    if (hasDiscount && countOfSmaps >= 5) {
      refHadDiscount.current = true;
      const smapDiscount =
        (priceSchema.smap - priceSchema.smap_discounted_convention) *
        countOfSmaps;
      dispatch(
        applySmapDiscount({
          total: smapDiscount,
          price: priceSchema.smap_discounted_convention,
        }),
      );
    } else if (refHadDiscount.current) {
      refHadDiscount.current = false;
      dispatch(removeSmapDiscount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items]);

  const getTotalOfPrices = (listOfPrices) => {
    const totalAmountWithDiscount = listOfPrices.reduce((prev, current) => {
      if (current.print.value)
        return prev + prices[current.key] + current.print.price;
      if (current.print.digital)
        return prev + prices[current.key] + current.print.digital;
      return prev + prices[current.key];
    }, 0);

    const totalAmountWithoutDiscount = listOfPrices.reduce((prev, current) => {
      if (current.print.value)
        return prev + defaultPrices[current.key] + current.print.price;
      if (current.print.digital)
        return prev + defaultPrices[current.key] + current.print.digital;
      return prev + defaultPrices[current.key];
    }, 0);

    return {
      totalWithDiscount: totalAmountWithDiscount.toFixed(2),
      totalWithoutDiscount: totalAmountWithoutDiscount.toFixed(2),
      totalOfDiscount: (
        totalAmountWithoutDiscount - totalAmountWithDiscount
      ).toFixed(2),
    };
  };

  useEffect(() => {
    const fetchPriceSchema = async () => {
      const { data: priceSchema } = await getPricingSchemes();
      setPriceSchema(priceSchema);
      const prices = {
        smap: priceSchema.smap,
        smau: priceSchema.smau,
        smar: priceSchema.smar,
        apv: priceSchema.apv,
        bfdv: priceSchema.bfdv,
        clamidia: priceSchema.clamidia,
        digital: priceSchema.digital,
        print: priceSchema.print,
      };
      // setOriginalPrices(prices);
      setPrices(prices);
    };
    fetchPriceSchema();
  }, []);

  useEffect(() => {
    const fetchDefaultPriceSchema = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getDefaultPricingSchemes();
      setDefaultPrices(attributes);
    };
    fetchDefaultPriceSchema();
  }, []);

  const documentFormatContainerClass = classNames(
    styles.bgYellowHover,
    'd-flex justify-content-center',
  );
  const { smap, smau, smar, apv, bfdv, clamidia, digital, print } = prices;

  const updateIdentifier = (newIdentifier) => {
    dispatch(updateIdentifierBySampleId(newIdentifier));
  };

  return (
    <>
      <Row
        className={classNames('px-2 px-md-3 px-lg-4', styles.summary)}
        ref={refTitle}
      >
        <SectionSubTitle icon="/assets/icons/local_grocery_store_24px.svg">
          CARRELLO ANALISI DA EFFETTUARE
        </SectionSubTitle>
      </Row>
      <SectionCart summary={cart} onUpdateIdentifier={updateIdentifier} />
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionTitle>
          {cart.items.length > 0
            ? 'INSERISCI NUOVO CAMPIONE'
            : 'INSERISCI CAMPIONE'}
        </SectionTitle>
        <Formik
          validationSchema={schema}
          validateOnMount
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            const sample = values.samplesAdded.map((_sample) => {
              return {
                ..._sample,
                description: _sample.key,
                price: prices[_sample.key],
              };
            });
            const { totalWithDiscount, totalWithoutDiscount, totalOfDiscount } =
              getTotalOfPrices(values.samplesAdded);
            resetForm();
            refSelect.current?.clear();
            dispatch(
              addElementToCart({
                ...cart,
                items: [
                  ...cart.items,
                  {
                    patientInfo: {
                      name: values.owner ? values.owner : full_name,
                      description: values.identifier,
                      birthDate: values.birthDate,
                      mutation: values.mutation,
                      owner: values.owner,
                    },
                    totalWithDiscount: Number(totalWithDiscount),
                    totalWithoutDiscount: Number(totalWithoutDiscount),
                    totalOfDiscount: Number(totalOfDiscount),
                    specieId: values?.specie?.id || null,
                    specie: values?.specie?.name
                      ? values.specie.name
                      : values.otherSpecie,
                    hasOtherSpecie: !!values.otherSpecie,
                    analyses: sample,
                    id: new Date().toISOString(),
                  },
                ],
                subTotal: cart.subTotal + Number(totalWithoutDiscount),
                discount: cart.discount + Number(totalOfDiscount),
                total: cart.total + Number(totalWithDiscount),
              }),
            );
            toast.success('Campione aggiunto con successo');
            refTitle?.current?.scrollIntoView();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
            setFieldTouched,
            isValid,
            dirty,
          }) => {
            const { samplesAdded, notFoundSpecie, specie } = values;

            const handleChecksChange = (e, belongsTo, type) => {
              handleChange(e);
              const index = samplesAdded.findIndex(
                ({ key: _key }) => _key === belongsTo,
              );
              const itemToModify = samplesAdded.find(
                ({ key: _key }) => _key === belongsTo,
              );
              const copySamplesAdded = [...samplesAdded];
              copySamplesAdded[index] = {
                ...itemToModify,
                [type]: {
                  value: Boolean(e.target.checked),
                  price: type === 'digital' ? digital : print,
                },
              };

              setFieldValue('samplesAdded', copySamplesAdded);
            };
            const handleSamplesCheckChange = (
              e,
              type,
              price,
              checksToReset,
            ) => {
              handleChange(e);
              const isChecked = Boolean(e.target.checked);
              for (const checkToReset of checksToReset) {
                setFieldValue(checkToReset, false);
              }
              if (isChecked) {
                const shouldRemoveMutationSample = [
                  'smap',
                  'smau',
                  'smar',
                ].includes(type);
                setFieldValue('samplesAdded', [
                  ...(shouldRemoveMutationSample
                    ? [
                      ...samplesAdded.filter(
                        ({ key: _key }) =>
                          !['smap', 'smau', 'smar'].includes(_key),
                      ),
                    ]
                    : [...samplesAdded]),
                  {
                    key: type,
                    price,
                    digital: {
                      value: false,
                      price: digital,
                    },
                    print: {
                      value: false,
                      price: print,
                    },
                  },
                ]);
              } else {
                setFieldValue('samplesAdded', [
                  ...samplesAdded.filter(({ key: _key }) => _key !== type),
                ]);
              }
              const resetValues = {};
              checksToReset.forEach((check) => {
                Object.assign(resetValues, { [check]: false });
              });
            };
            const { totalWithDiscount, totalWithoutDiscount } =
              getTotalOfPrices(samplesAdded);
            return (
              <Form
                noValidate
                onSubmit={async (e) => {
                  handleSubmit(e);
                  ((!isValid && !dirty) || !isValid) &&
                    toast.error('È necessario compilare i dati richiesti');
                }}
              >
                {/* First Row with two columns */}
                <Row>
                  <Col sm={6}>
                    {/*  Identifier Text Field*/}
                    <Row>
                      <Col sm={6}>
                        <SectionLabel
                          icon="/assets/icons/contact_support_24px.svg"
                          prefixIcon={true}
                          lightFont={true}
                        >
                          {t('order_cart.identifier', '')}{' '}
                          <span className="text-primary ms-1">*</span>
                        </SectionLabel>
                      </Col>
                      <Col sm={5}>
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Form.Control
                              placeholder={t('order_cart.identifier', '')}
                              type="text"
                              name="identifier"
                              value={values.identifier}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.identifier && !!errors.identifier
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.identifier}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/*  Specie Select Field */}
                    <Row className="mt-4">
                      <Col sm={6}>
                        <SectionLabel
                          icon="/assets/icons/contact_support_24px.svg"
                          prefixIcon={true}
                          lightFont={true}
                        >
                          Specie <span className="text-primary ms-1">*</span>
                        </SectionLabel>
                      </Col>
                      <Col sm={5}>
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <SelectAutoComplete
                              id="species"
                              fetchData={handleSearchSpecies}
                              perPage={10}
                              onChange={(data) =>
                                handleChangeSpecies(data, setFieldValue)
                              }
                              onFocus={() => setFieldTouched('specie', true)}
                              isInvalid={touched.specie && !!errors?.specie}
                              disabled={notFoundSpecie}
                              placeholder={t('order_cart.specie.placeholder', '')}
                              refSelect={refSelect}
                              className={
                                touched.specie &&
                                  !!errors?.specie &&
                                  !notFoundSpecie
                                  ? 'is-invalid'
                                  : 'is-valid'
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/*  Not found Specie Select Field */}
                    {notFoundSpecie && (
                      <Row className="mt-4">
                        <Col sm={6} className="d-none d-md-block" />
                        <Col sm={5}>
                          <Form.Group as={Row}>
                            <Col sm={12}>
                              <Form.Control
                                placeholder="Altra specie"
                                type="text"
                                name="otherSpecie"
                                value={values.otherSpecie}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!notFoundSpecie}
                                isInvalid={
                                  touched.otherSpecie && !!errors.otherSpecie
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.otherSpecie}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col sm={5}>
                    {/*  Alert box for put the specie and sub-specie or alert */}
                    {!notFoundSpecie ? (
                      <div className={classNames('p-2')}>
                        <p className="p-2 mb-0">
                          <span className="fw-bold">SPECIE: </span>
                          {specie.specie}
                          <br />
                          <span className="fw-bold">SOTTOSPECIE: </span>
                          {specie.subSpecie}
                          <br />
                          <span className="fw-bold">NOMI COMUNI: </span>
                          {specie.common}
                        </p>
                      </div>
                    ) : (
                      <div className={classNames('p-2')}>
                        <Alert
                          variant="danger"
                          className="p-2 mb-0 text-center"
                        >
                          <i className="fa fa-exclamation-triangle" />{' '}
                          ATTENZIONE{' '}
                          <i className="fa fa-exclamation-triangle" />
                          <br />
                          L’analisi di specie non presenti in elenco, potrebbe
                          rallentare i tempi di elaborazione dell'ordine
                        </Alert>
                      </div>
                    )}
                  </Col>
                </Row>
                {/* Second Row with two columns */}
                <Row className="mt-4">
                  {/* Specie not found Check Field */}
                  <Col sm={6}>
                    <Form.Check
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('otherSpecie', '');
                        setFieldValue('specie', defaultSpecie);
                        refSelect.current?.clear();
                      }}
                      name="notFoundSpecie"
                      type="checkbox"
                      label="Specie non trovata?"
                      checked={values.notFoundSpecie}
                    />
                  </Col>
                  {/* Anchor to redirect to know more about species */}
                  <Col sm={6}>
                    <p>
                      Se non conosci il nome scientifico, clicca{' '}
                      <a
                        rel="noreferrer"
                        href="https://avibase.bsc-eoc.org/avibase.jsp"
                        target="_blank"
                      >
                        qui
                      </a>
                    </p>
                  </Col>
                </Row>
                {/* Third Row with two columns */}
                <Row>
                  <Col sm={5}>
                    {/* Mutation Text Field */}
                    <Row>
                      <Col sm={6}>
                        <SectionLabel
                          icon="/assets/icons/contact_support_24px.svg"
                          prefixIcon={true}
                          lightFont={true}
                        >
                          Mutazione
                        </SectionLabel>
                      </Col>
                      <Col sm={6}>
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Form.Control
                              placeholder="Mutazione"
                              type="text"
                              name="mutation"
                              value={values.mutation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.mutation && !!errors.mutation}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.mutation}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={{ span: 5, offset: 1 }}></Col>
                </Row>
                {/* Date of birth Date Field */}
                <Row className="mt-4">
                  <Col sm={5}>
                    <Row>
                      <Col sm={6}>
                        <SectionLabel
                          icon="/assets/icons/contact_support_24px.svg"
                          prefixIcon={true}
                          lightFont={true}
                        >
                          Data di nascita
                        </SectionLabel>
                      </Col>
                      <Col sm={6}>
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Form.Control
                              placeholder="Data di nascita"
                              type="date"
                              name="birthDate"
                              value={values.birthDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.birthDate && !!errors.birthDate
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.birthDate}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={{ span: 5, offset: 1 }}></Col>
                </Row>
                <Row className={'mt-4'}>
                  <Col sm={4} className={'fw-bold'}>
                    <span className={classNames(styles.accentLabel)}>
                      Esame
                    </span>
                  </Col>
                  <Col sm={4} className={'fw-bold'}>
                    <span className={classNames(styles.accentLabel)}>
                      Attestato
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={7}>
                    <Table hover size="sm">
                      <thead>
                        <tr>
                          <th>Sessagio Molecolare</th>
                          <th>Prezzo</th>
                          <th>Cartaceo</th>
                          <th>PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                onChange={(e) => {
                                  handleSamplesCheckChange(e, 'smap', smap, [
                                    'smapPrint',
                                    'smapDigital',
                                    'smau',
                                    'smauPrint',
                                    'smauDigital',
                                    'smar',
                                    'smarPrint',
                                    'smarDigital',
                                  ]);
                                }}
                                type="checkbox"
                                name="smap"
                                checked={values.smap}
                                className="me-1 me-md-2"
                              />
                              Sessagio da piuma, sangue, swab
                            </div>
                          </td>
                          <td>(€{smap})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smap', 'print')
                                    }
                                    checked={values.smapPrint}
                                    name="smapPrint"
                                    type="checkbox"
                                    disabled={!values.smap}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                    title="test"
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smap', 'digital')
                                    }
                                    checked={values.smapDigital}
                                    name="smapDigital"
                                    type="checkbox"
                                    disabled={!values.smap}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                name="smau"
                                checked={values.smau}
                                className="me-1 me-md-2"
                                onChange={(e) => {
                                  handleSamplesCheckChange(e, 'smau', smau, [
                                    'smauPrint',
                                    'smauDigital',
                                    'smar',
                                    'smarPrint',
                                    'smarDigital',
                                    'smap',
                                    'smapPrint',
                                    'smapDigital',
                                  ]);
                                }}
                              />
                              Sessagio da uovo
                            </div>
                          </td>
                          <td>(€{smau})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smau', 'print')
                                    }
                                    name="smauPrint"
                                    type="checkbox"
                                    disabled={!values.smau}
                                    checked={values.smauPrint}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smau', 'digital')
                                    }
                                    name="smauDigital"
                                    type="checkbox"
                                    disabled={!values.smau}
                                    checked={values.smauDigital}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                name="smar"
                                checked={values.smar}
                                className="me-1 me-md-2"
                                onChange={(e) => {
                                  handleSamplesCheckChange(e, 'smar', smar, [
                                    'smarPrint',
                                    'smartDigital',
                                    'smap',
                                    'smapPrint',
                                    'smapDigital',
                                    'smau',
                                    'smauPrint',
                                    'smauDigital',
                                  ]);
                                }}
                              />
                              Sessagio rapido RAPIDO
                            </div>
                          </td>
                          <td>(€{smar})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smar', 'print')
                                    }
                                    checked={values.smarPrint}
                                    name="smarPrint"
                                    type="checkbox"
                                    disabled={!values.smar}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'smar', 'digital')
                                    }
                                    checked={values.smarDigital}
                                    name="smarDigital"
                                    type="checkbox"
                                    disabled={!values.smar}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <th>Analisi virali da penna o sangue</th>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                className="me-1 me-md-2"
                                checked={values.apv}
                                name="apv"
                                onChange={(e) => {
                                  handleSamplesCheckChange(e, 'apv', apv, [
                                    'apvPrint',
                                    'apvDigital',
                                  ]);
                                }}
                              />
                              APV
                            </div>
                          </td>
                          <td>(€{apv})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'apv', 'print')
                                    }
                                    name="apvPrint"
                                    checked={values.apvPrint}
                                    type="checkbox"
                                    disabled={!values.apv}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'apv', 'digital')
                                    }
                                    name="apvDigital"
                                    checked={values.apvDigital}
                                    type="checkbox"
                                    disabled={!values.apv}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                className="me-1 me-md-2"
                                checked={values.bfdv}
                                name="bfdv"
                                onChange={(e) => {
                                  handleSamplesCheckChange(e, 'bfdv', bfdv, [
                                    'bfdvPrint',
                                    'bfdvDigital',
                                  ]);
                                }}
                              />
                              BFDV
                            </div>
                          </td>
                          <td>(€{bfdv})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'bfdv', 'print')
                                    }
                                    checked={values.bfdvPrint}
                                    name="bfdvPrint"
                                    type="checkbox"
                                    disabled={!values.bfdv}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'bfdv', 'digital')
                                    }
                                    checked={values.bfdvDigital}
                                    name="bfdvDigital"
                                    type="checkbox"
                                    disabled={!values.bfdv}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <th>Analisi da tamponi clocali o feci</th>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                className="me-1 me-md-2"
                                checked={values.clamidia}
                                name="clamidia"
                                onChange={(e) => {
                                  handleSamplesCheckChange(
                                    e,
                                    'clamidia',
                                    clamidia,
                                    ['clamidiaPrint', 'clamidiaDigital'],
                                  );
                                }}
                              />
                              Clamidia
                            </div>
                          </td>
                          <td>(€{clamidia})</td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_PRINT_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(e, 'clamidia', 'print')
                                    }
                                    checked={values.clamidiaPrint}
                                    name="clamidiaPrint"
                                    type="checkbox"
                                    disabled={!values.clamidia}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/receipt_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                          <td>
                            <Row>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>{TOOLTIP_DIGITAL_TEXT}</Tooltip>
                                }
                              >
                                <Col
                                  sm={8}
                                  className={documentFormatContainerClass}
                                >
                                  <Form.Check
                                    onChange={(e) =>
                                      handleChecksChange(
                                        e,
                                        'clamidia',
                                        'digital',
                                      )
                                    }
                                    checked={values.clamidiaDigital}
                                    name="clamidiaDigital"
                                    type="checkbox"
                                    disabled={!values.clamidia}
                                  />
                                  <Image
                                    className={'img-fluid ms-2'}
                                    src={'/assets/icons/save_24px.svg'}
                                  />
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={5}>
                    <div className={classNames('topShadow p-1 p-md-3')}>
                      <h4 className="m-0 ">ANALISI SELEZIONATE</h4>
                      {samplesAdded.length > 0 ? (
                        <Table borderless>
                          <tbody>
                            {samplesAdded.map(
                              ({ key, digital, print }, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {key}
                                      {prices[key] === defaultPrices[key] ? (
                                        <> (€ {prices[key]})</>
                                      ) : (
                                        <>
                                          (<s>€ {defaultPrices[key]})</s> (€{' '}
                                          {prices[key]})
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {print.value && (
                                        <>
                                          <Image
                                            className={'img-fluid mx-1'}
                                            src={
                                              '/assets/icons/receipt_24px.svg'
                                            }
                                            title="test"
                                          />{' '}
                                          (€ {print.price})
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {digital.value && (
                                        <>
                                          <Image
                                            className={'img-fluid mx-1'}
                                            src={'/assets/icons/save_24px.svg'}
                                            title="test"
                                          />{' '}
                                          (€ {digital.price})
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {index === samplesAdded.length - 1
                                        ? '='
                                        : '+'}
                                    </td>
                                  </tr>
                                );
                              },
                            )}
                            <tr>
                              <td colSpan={3}>
                                <b>Totale campione</b>
                              </td>
                              <td>
                                <b>
                                  {totalWithoutDiscount !==
                                    totalWithDiscount && (
                                      <s>€{totalWithoutDiscount}</s>
                                    )}
                                  € {totalWithDiscount}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <p className="mt-2">Nessuna analisi selezionata</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} className={'d-flex'}>
                    <SectionLabel
                      icon="/assets/icons/contact_support_24px.svg"
                      prefixIcon={true}
                      lightFont={true}
                    >
                      Proprietario
                    </SectionLabel>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Nome e cognome"
                      type="text"
                      name="owner"
                      value={values.owner}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.owner && !!errors.owner}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.owner}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className={'mt-4'}>
                  <Col
                    sm={{ span: 4, offset: 3 }}
                    className={documentFormatContainerClass}
                  >
                    <Button type="submit">
                      {t('order_cart.table.submit', '')}
                      <Image
                        width="20px"
                        height="20px"
                        className="img-fluid ms-2"
                        src={'/assets/icons/cancel_24px.svg'}
                      />
                    </Button>
                  </Col>
                </Row>
                <SectionTitle>Fatturazione</SectionTitle>
                <Form.Check
                  className="mb-3"
                  label="Verifica se hai bisogno di una fattura"
                  name="invoice"
                  checked={requiredInvoice}
                  onChange={(e) => dispatch(addInvoiceToCart(e.target.checked))}
                />
                <Row className={'mt-4'}>
                  <Col
                    sm={{ span: 11 }}
                    className={'d-flex justify-content-end'}
                  >
                    <Button type="button" onClick={handleNextStep}>
                      Vai al Riepilogo
                      <Image
                        width="10px"
                        height="10px"
                        className="img-fluid ms-2"
                        src={'/assets/icons/next_arrow.svg'}
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Row>
    </>
  );
};

export default Cart;
