import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

const getPositionForLogo = (_pathname) => {
  switch (_pathname) {
    case '/personal/orders-history':
      return 'right';
    case '/personal/edit-data':
      return 'left';
    case '/personal/package-credits':
      return 'right';
    case '/login':
      return 'left';
    case '/register':
      return 'right';
    default:
      return 'right';
  }
};

const Logo = () => {
  const { pathname } = useLocation();
  const containerLogoStyles = classNames('d-flex', {
    'justify-content-end': getPositionForLogo(pathname) === 'right',
  });
  return (
    <Row className="mt-3 mt-md-5 mb-4">
      <Col className={containerLogoStyles}>
        <Image className="img-fluid" src="/assets/login-logo_nero.png" />
      </Col>
    </Row>
  );
};

export default Logo;
