import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import order from 'app/main/pages/Order/store';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ...asyncReducers,
    auth,
    order,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'auth/user/userLoggedOut') {
    state = undefined;
    console.log(state);
  }

  return combinedReducer(state, action);
};

export default createReducer;
