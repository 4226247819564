import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import FemAuthorization from '@fem/core/FemAuthorization';
import AppContext from './AppContext';
import routes from './configs/routesConfig';
import Layout from './layout/Layout';
import store from './store';
import { ToastContainer } from 'react-toastify';
import { Auth } from './auth';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Auth>
            <BrowserRouter>
              <FemAuthorization>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  closeOnClick={true}
                  pauseOnHover={true}
                  draggable={true}
                  progress={undefined}
                />
                <Layout />
              </FemAuthorization>
            </BrowserRouter>
          </Auth>
        </QueryClientProvider>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
