import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error500Page = function ({ error, resetErrorBoundary }) {
  const { t } = useTranslation();

  return (
    <Alert variant="danger" role="alert">
      <p>{t('error_500.title', '')}</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>
        {t('error_500.subtitle', '')}
      </button>
    </Alert>
  );
};

export default Error500Page;
