import React from 'react';
import { Step, Stepper } from 'react-form-stepper';
import styles from './Order.module.scss';
import { Image, Row } from 'react-bootstrap';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Summary from 'app/main/pages/Orders/Order/Summary/Summary';
import { Redirect } from 'react-router';
import { default as CS } from 'classnames/bind';
import Logo from '../../../components/Logo';
import SectionTitle from '../../../components/SectionTitle';
import Payment from './Payment/Payment';

const steps = [
  {
    label: 'Riepilogo',
    active: false,
    activeIconPath: '/assets/icons/view_list_24px.svg',
    inactiveIconPath: '/assets/icons/view_list_active_24px.svg',
    activeBgColor: '#eea236',
    inactiveBgColor: '#F3EDE2',
    completedBgColor: '#F3EDE2',
  },
  {
    label: 'Pagamento',
    active: false,
    activeIconPath: '/assets/icons/euro.svg',
    inactiveIconPath: '/assets/icons/euro.svg',
    activeBgColor: '#eea236',
    inactiveBgColor: '#F3EDE2',
    completedBgColor: '#F3EDE2',
  },
  {
    label: 'Conferma',
    active: false,
    activeIconPath: '/assets/icons/check_24px.svg',
    inactiveIconPath: '/assets/icons/check_24px.svg',
    activeBgColor: '#BFDCD3',
    inactiveBgColor: '#F3EDE2',
    completedBgColor: '#F3EDE2',
  },
];
const stepsRoutes = ['summary', 'payment'];
const classNames = CS.bind(styles);

function Order({ currentStep, samples, orderId, order }) {
  let { url } = useRouteMatch();
  const history = useHistory();

  const handleNextStep = () => {
    history.push(`${url}/${stepsRoutes[currentStep + 1]}`);
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      history.push(`${url}/${stepsRoutes[currentStep - 1]}`);
    }
  };

  const handleStepClick = (step) => {
    if (step !== currentStep) {
      history.push(`${url}/${stepsRoutes[step]}`);
    }
  };
  console.log(order);
  return (
    <div>
      <Logo />
      <Row className={classNames('p-2 p-md-3 p-lg-4', styles.wrapper)}>
        <SectionTitle>Continuare con l'ordine</SectionTitle>
        <Stepper
          activeStep={currentStep}
          stepClassName={classNames(styles.step)}
        >
          {steps.map((step, index) => {
            return (
              <Step
                label={step.label}
                key={index}
                onClick={() => handleStepClick(index)}
                styleConfig={{
                  activeBgColor: step.activeBgColor,
                  inactiveBgColor: step.inactiveBgColor,
                  completedBgColor: step.completedBgColor,
                  borderRadius: '8%',
                  size: '4em',
                }}
              >
                <Image
                  src={
                    index === currentStep
                      ? step.activeIconPath
                      : step.inactiveIconPath
                  }
                />
              </Step>
            );
          })}
        </Stepper>
        <Switch>
          <Route path={`${url}/summary`}>
            <Summary
              handleNextStep={handleNextStep}
              samples={samples}
              totalOrder={order.amount}
            />
          </Route>
          <Route path={`${url}/payment`}>
            <Payment
              handlePrevStep={handlePrevStep}
              samples={samples}
              orderId={orderId}
              total={
                order?.amount ? Number(order.amount).toFixed(2) : (0).toFixed(2)
              }
            />
          </Route>
          <Route path={`${url}/*`}>
            <Redirect to={`${url}`} />
          </Route>
        </Switch>
      </Row>
    </div>
  );
}

export default Order;
