import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { postCredits } from '@fem/cruds/creditCrud';
import { useDispatch } from 'react-redux';
import { setNewCredits } from 'app/auth/store/userSlice';

const PaypalPackages = (props) => {
  let location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const countCreditTokens = (info) => {
    postCredits({ token: info.token })
      .then((response) => {
        const data = response.data;
        const credits = data.credits;
        dispatch(setNewCredits(credits));
        history.push('/personal/package-credits');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    countCreditTokens(parsed);
    // eslint-disable-next-line
  }, []);

  return '';
};

export default PaypalPackages;
