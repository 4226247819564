import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { default as _classNames } from 'classnames/bind';
import styles from './PricingTable.module.scss';

const PricingTable = ({ prices, getSelectedPrice, currentPrice }) => {
  const classNames = _classNames.bind(styles);
  const [priceClicked, setPriceClicked] = useState(currentPrice);

  const handlerPriceClick = (e, id) => {
    setPriceClicked(id);
    getSelectedPrice(prices.find((price) => price.id === id));
  };

  // effect to reset
  useEffect(() => {
    if (currentPrice === undefined) setPriceClicked(currentPrice);
  }, [currentPrice]);

  return (
    <Row className={styles.wrapper}>
      {prices &&
        prices.length > 0 &&
        prices.map(({ subtitle, price, id, title }) => {
          return (
            <Col sm={4} key={id} onClick={(e) => handlerPriceClick(e, id)}>
              <div
                className={classNames(styles.pricingTable, {
                  active: id === priceClicked,
                })}
              >
                <div className={styles.pricingTableHeader}>
                  <div className={styles.priceValue}>
                    <span className={styles.textPrice}>{title}</span>
                  </div>
                </div>
                <div className={styles.prices}>
                  <p className={styles.pricePerUnit}>{subtitle}</p>
                  <p className={styles.totalPrice}> {price}€</p>
                </div>
              </div>
            </Col>
          );
        })}
    </Row>
  );
};

export default PricingTable;
