import React, { useContext, useState } from 'react';
import AppContext from 'app/AppContext';
import FemSuspense from '@fem/core/FemSuspense';
import { renderRoutes } from 'react-router-config';
import { Container, Row, Offcanvas, Navbar, Nav } from 'react-bootstrap';
import {
  HouseDoor,
  PersonCircle,
  PersonLinesFill,
  Power,
  HouseFill,
} from 'react-bootstrap-icons';
import { useLocation, NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import './layout.scss';
import { logoutUser } from 'app/auth/store/userSlice';
import { useTranslation } from 'react-i18next';

const getBackgroundImg = (_pathname) => {
  switch (_pathname) {
    case '/login':
      return '/assets/login-bird.svg';
    case '/register':
      return '/assets/register-bird.png';
    case '/personal/orders-history':
      return '/assets/personal-order-history-bird.webp';
    case '/personal/edit-data':
      return '/assets/personal-edit-data-bird.webp';
    case '/personal/package-credits':
      return '/assets/personal-package-credits-bird.webp';
    default:
      return '/assets/login-bird.svg';
  }
};

const Layout = () => {
  const [show, setShow] = useState(false);
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const offCanvasNavLinkStyles = classNames(
    'd-flex',
    'justify-content-end',
    'align-items-center',
    'p-2',
    ' p-md-3',
    'p-lg-4',
    'off-canvas-nav-bar',
  );
  const { route } = matchRoutes(routes, pathname)[0];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (route?.settings?.layout?.custom) {
    return <div>{renderRoutes(routes)}</div>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundImage: `url("${getBackgroundImg(pathname)}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Container className="pt-2 pt-md-5">
        <Navbar bg="light" expand="lg">
          <Container fluid className="d-flex flex-lg-row flex-row-reverse">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              onClick={handleShow}
            />
            <Nav
              activeKey={pathname}
              className={classNames(
                'd-flex justify-content-between nav-container',
              )}
            >
              <div className="d-flex flex-row">
                <Nav.Link
                  as={NavLink}
                  className="d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                  to="/personal"
                >
                  <HouseDoor size={20} className="mx-2" />
                  {t('tabs.header.home', '')}
                </Nav.Link>
                <Nav className="d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4">
                  <HouseFill size={20} className="mx-2" />
                  <a
                    className="text-decoration-none text-dark"
                    href="https://avifauna.fem2ambiente.com/"
                  >
                    {t('tabs.header.web', '')}
                  </a>
                </Nav>
                {route?.settings?.layout?.config?.navbar?.generalMenu &&
                  !window.localStorage.getItem('jwt_access_token') && (
                    <>
                      <Nav.Link
                        as={NavLink}
                        className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                        to="/login"
                      >
                        <PersonCircle size={20} className="mx-2" />
                        {t('tabs.header.login', '')}
                      </Nav.Link>

                      <Nav.Link
                        as={NavLink}
                        className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                        to="/register"
                      >
                        <PersonLinesFill size={20} className="mx-2" />
                        {t('tabs.header.register', '')}
                      </Nav.Link>
                    </>
                  )}
                {route?.settings?.layout?.config?.navbar?.personalMenu && (
                  <>
                    <Nav.Link
                      eventKey={4}
                      as={NavLink}
                      className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                      to="/personal/orders-history"
                    >
                      <PersonCircle size={20} className="mx-2" />
                      {t('tabs.header.orders', '')}
                    </Nav.Link>
                    <Nav.Link
                      eventKey={5}
                      as={NavLink}
                      className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                      to="/personal/edit-data"
                    >
                      <PersonLinesFill size={20} className="mx-2" />
                      {t('tabs.header.personal', '')}
                    </Nav.Link>
                    <Nav.Link
                      eventKey={6}
                      as={NavLink}
                      className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                      to="/personal/package-credits"
                    >
                      <PersonLinesFill size={20} className="mx-2" />
                      {t('tabs.header.packages', '')}
                    </Nav.Link>
                  </>
                )}
              </div>
              <div className="d-flex flex-row">
                {window.localStorage.getItem('jwt_access_token') && (
                  <Nav.Link
                    className="d-none d-lg-block d-flex justify-content-center align-items-center p-2 p-md-3 p-lg-4"
                    onClick={() => dispatch(logoutUser())}
                  >
                    <Power size={20} className="mx-2" />
                    Logout
                  </Nav.Link>
                )}
              </div>
            </Nav>

            <Navbar.Offcanvas
              placement="end"
              variant="light"
              onHide={handleClose}
              show={show}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Navbar bg="light" expand="lg" onSelect={handleClose}>
                  <Nav
                    className="justify-content-end flex-grow-1 pe-3"
                    activeKey={pathname}
                  >
                    <Nav.Link
                      eventKey={1}
                      as={NavLink}
                      className={offCanvasNavLinkStyles}
                      to="/personal"
                    >
                      <HouseDoor size={20} className="mx-2" />
                      {t('tabs.header.home', '')}
                    </Nav.Link>
                    {route?.settings?.layout?.config?.navbar?.generalMenu &&
                      !window.localStorage.getItem('jwt_access_token') && (
                        <>
                          <Nav.Link
                            eventKey={2}
                            as={NavLink}
                            className={offCanvasNavLinkStyles}
                            to="/login"
                          >
                            <PersonCircle size={20} className="mx-2" />
                            {t('tabs.header.login', '')}
                          </Nav.Link>
                          <Nav.Link
                            eventKey={3}
                            as={NavLink}
                            className={offCanvasNavLinkStyles}
                            to="/register"
                          >
                            <PersonLinesFill size={20} className="mx-2" />
                            {t('tabs.header.register', '')}
                          </Nav.Link>
                        </>
                      )}
                    {route?.settings?.layout?.config?.navbar?.personalMenu && (
                      <>
                        <Nav.Link
                          eventKey={4}
                          as={NavLink}
                          className={offCanvasNavLinkStyles}
                          to="/personal/orders-history"
                        >
                          <PersonCircle size={20} className="mx-2" />
                          {t('tabs.header.orders', '')}
                        </Nav.Link>
                        <Nav.Link
                          eventKey={5}
                          as={NavLink}
                          className={offCanvasNavLinkStyles}
                          to="/personal/edit-data"
                        >
                          <PersonLinesFill size={20} className="mx-2" />
                          {t('tabs.header.personal', '')}
                        </Nav.Link>
                        <Nav.Link
                          eventKey={6}
                          as={NavLink}
                          className={offCanvasNavLinkStyles}
                          to="/personal/package-credits"
                        >
                          <PersonLinesFill size={20} className="mx-2" />
                          {t('tabs.header.packages', '')}
                        </Nav.Link>
                        {window.localStorage.getItem('jwt_access_token') && (
                          <Nav.Link
                            className={offCanvasNavLinkStyles}
                            onClick={() => dispatch(logoutUser())}
                          >
                            <Power size={20} className="mx-2" />
                            {t('tabs.header.logout', '')}
                          </Nav.Link>
                        )}
                      </>
                    )}
                  </Nav>
                </Navbar>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <FemSuspense>{renderRoutes(routes)}</FemSuspense>
        <Row className="my-5" />
      </Container>
    </div>
  );
};

export default Layout;
