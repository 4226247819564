export const OrdersStatus = {
  RNS_FEM: 'RNS_FEM',
  RNS_CLIENTE: 'RNS_CLIENTE',
  PAGAMENTO: 'PAGAMENTO',
  MATERIALE: 'MATERIALE',
  ANALISI_PARZIALE: 'ANALISI_PARZIALE',
  ANALISI: 'ANALISI',
  CARTACEI: 'CARTACEI',
  FATTURAZIONE: 'FATTURAZIONE',
  CHIUSO: 'CHIUSO',
  ANNULLATO: 'ANNULLATO',
};

export const getClassOrNameByStatus = (status) =>
  ({
    [OrdersStatus.RNS_FEM]: {
      classForBgBadge: 'dark',
      textForBadge: 'Awaiting RNS Managing by FEM2 Ambiente',
    },
    [OrdersStatus.RNS_CLIENTE]: {
      classForBgBadge: 'dark',
      textForBadge: 'Awaiting RNS Managing by Cliente',
    },
    [OrdersStatus.PAGAMENTO]: {
      classForBgBadge: 'dark',
      textForBadge: 'PAGAMENTO',
    },
    [OrdersStatus.MATERIALE]: {
      classForBgBadge: 'dark',
      textForBadge: 'Attesa Campioni',
    },
    [OrdersStatus.ANALISI_PARZIALE]: {
      classForBgBadge: 'dark',
      textForBadge: 'ANALISI_PARZIALE',
    },
    [OrdersStatus.ANALISI]: {
      classForBgBadge: 'dark',
      textForBadge: 'ANALISI',
    },
    [OrdersStatus.CARTACEI]: {
      classForBgBadge: 'dark',
      textForBadge: 'CARTACEI',
    },
    [OrdersStatus.FATTURAZIONE]: {
      classForBgBadge: 'dark',
      textForBadge: 'FATTURAZIONE',
    },
    [OrdersStatus.CHIUSO]: {
      classForBgBadge: 'success',
      textForBadge: 'Completo',
    },
    [OrdersStatus.ANNULLATO]: {
      classForBgBadge: 'warning',
      textForBadge: 'Cancellato',
    },
  }[status] || {
    classForBgBadge: 'info',
    textForBadge: status,
  });
