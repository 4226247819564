import React, { useState, useEffect } from 'react';
import styles from './OrdersHistory.module.scss';
import { default as CS } from 'classnames/bind';
import SectionTitle from 'app/main/components/SectionTitle';
import { Row, Col, Button, Table, Badge, Image } from 'react-bootstrap';
import SectionSubTitle from 'app/main/components/SectionSubtitle';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OrdersStatus, getClassOrNameByStatus } from './util';
import { getMyOrders } from '@fem/cruds/orderCrud';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const classNames = CS.bind(styles);

function PaginatedTable({ itemsPerPage, items, status = [] }) {
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  if (status && status.length > 0) {
    items = items.filter((order) => status.includes(order.status));
  }
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, status]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  if (currentItems && currentItems.length === 0) {
    return <p>{t('orders.current_orders_table.no_data', '')}</p>;
  }

  return (
    <>
      <Table hover responsive="lg">
        <thead>
          <tr>
            <th>{t('orders.current_orders_table.date', '')}</th>
            <th>{t('orders.current_orders_table.number', '')}</th>
            <th>{t('orders.current_orders_table.status', '')}</th>
          </tr>
        </thead>
        <tbody>
          {currentItems && currentItems.length > 0 && (
            <>
              {currentItems.map(({ createdAt, id, status }) => {
                const { classForBgBadge, textForBadge } =
                  getClassOrNameByStatus(status);
                return (
                  <tr
                    className={styles.rowTable}
                    role="button"
                    key={id}
                    onClick={() => history.push(`/orders/${id}`)}
                  >
                    <td>
                      <Moment format="LLLL">{createdAt}</Moment>
                    </td>
                    <td># {id}</td>
                    <td>
                      <Badge bg={classForBgBadge}>{textForBadge}</Badge>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="pagination pagination-sm"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
        disabledLinkClassName="disabled"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
      />
    </>
  );
}

const OrdersHistory = () => {
  let history = useHistory();
  const [orders, setOrders] = useState([]);
  const [totalOfCurrentOrders, setTotalOfCurrentOrders] = useState(0);
  const [totalOfCompletedOrders, setTotalOfCompletedOrders] = useState(0);
  const formStyles = classNames('p-2 p-md-3 p-lg-4', styles.wrapper);
  const {
    full_name,
    client: { fem_credit },
  } = useSelector(({ auth }) => auth.user.data);

  const { t } = useTranslation();

  useEffect(() => {
    const _getMyOrders = async () => {
      try {
        const {
          data: { data },
        } = await getMyOrders();
        const orders = data.map(({ attributes, id }) => ({
          ...attributes,
          id,
        }));
        setOrders(orders);
      } catch (e) {
        console.error(e);
      }
    };
    _getMyOrders();
  }, []);

  useEffect(() => {
    const currentOrders = orders.filter((order) =>
      [
        OrdersStatus.RNS_FEM,
        OrdersStatus.RNS_CLIENTE,
        OrdersStatus.ANALISI,
        OrdersStatus.ANALISI_PARZIALE,
        OrdersStatus.CARTACEI,
        OrdersStatus.FATTURAZIONE,
        OrdersStatus.PAGAMENTO,
        OrdersStatus.MATERIALE,
      ].includes(order.status),
    );

    const completedOrders = orders.filter((order) =>
      [OrdersStatus.CHIUSO, OrdersStatus.ANNULLATO].includes(order.status),
    );

    setTotalOfCurrentOrders(currentOrders.length);
    setTotalOfCompletedOrders(completedOrders.length);
  }, [orders]);

  return (
    <Row className={formStyles}>
      <SectionTitle>{t('orders.title', '')}</SectionTitle>
      <p>{t('orders.welcome', { name: full_name }, '')}</p>
      <p>{t('orders.message', '')}</p>
      <Row>
        <Col xs={6} sm={4}>
          <Button onClick={() => history.push('/order/cart')}>
            {t('orders.new_order_btn', '')}
            <Image
              width="20px"
              height="20px"
              className="img-fluid ms-2"
              src={'/assets/icons/local_mall_24px_outlined.svg'}
            />
          </Button>
        </Col>
        <Col
          xs={6}
          sm={8}
          className="d-flex justify-content-end align-items-center"
        >
          <div>
            <Button disabled variant="secondary">
              {fem_credit} €
            </Button>
          </div>
          <div className="ms-3 d-none d-sm-block">
            {t('orders.current_orders_title', '')}
            <b className="ms-1 ms-md-2 ms-lg-3">{totalOfCurrentOrders}</b>{' '}
            <span className="mx-2">|</span> {t('orders.complete', '')}{' '}
            <b className="ms-1 ms-md-2 ms-lg-3">{totalOfCompletedOrders}</b>
          </div>
        </Col>
      </Row>
      <SectionSubTitle icon="/assets/icons/rotate_right_24px_outlined.svg">
        {t('orders.current_orders_table.open_title', '')}{' '}
      </SectionSubTitle>
      <Row>
        <Col sm={10} lg={8}>
          <PaginatedTable
            itemsPerPage={10}
            items={orders}
            status={[
              OrdersStatus.RNS_FEM,
              OrdersStatus.RNS_CLIENTE,
              OrdersStatus.ANALISI,
              OrdersStatus.ANALISI_PARZIALE,
              OrdersStatus.CARTACEI,
              OrdersStatus.FATTURAZIONE,
              OrdersStatus.PAGAMENTO,
              OrdersStatus.MATERIALE,
            ]}
          />
        </Col>
      </Row>
      <SectionSubTitle icon="/assets/icons/rotate_right_24px_outlined.svg">
        {t('orders.current_orders_table.close_title', '')}{' '}
      </SectionSubTitle>
      <Row>
        <Col sm={10} lg={8}>
          <PaginatedTable
            itemsPerPage={10}
            items={orders}
            status={[OrdersStatus.CHIUSO, OrdersStatus.ANNULLATO]}
          />
        </Col>
      </Row>
    </Row>
  );
};

export default OrdersHistory;
