import Login from './Login';
import authRoles from 'app/auth/authRoles';

const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          generalMenu: true,
          personalMenu: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/login',
      component: Login,
    },
  ],
};

export default LoginConfig;
