import axios from 'axios';
import { BASE_URL } from './constants';

export const getPackages = () => {
  return axios.get(`${BASE_URL}/api/packages?populate=pricing_scheme`);
};

export const getUserPackages = () => {
  return axios.get(`${BASE_URL}/api/packages/me?sort=updatedAt:desc`);
};

export const postUserPackages = (data) => {
  return axios.post(`${BASE_URL}/api/packages/me`, data);
};

export const postUserPackagesPaypal = (data) => {
  return axios.post(`${BASE_URL}/api/packages/paypal`, data);
};

export const postCredits = (data) => {
  return axios.post(`${BASE_URL}/api/packages/credits/count`, data);
};
